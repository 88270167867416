import React from 'react';
import { Helmet } from 'react-helmet-async';

const DistanceSellingScreen = () => (
  <>
    <Helmet>
      <title>Mesafeli Satış Sözleşmesi</title>
      <meta name="description" content="Mesafeli Satış Sözleşmesi" />
    </Helmet>
    <div style={{ padding: '20px', lineHeight: '1.6', fontFamily: 'Arial, sans-serif' }}>
      <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Mesafeli Satış Sözleşmesi</h1>
      <p style={{ whiteSpace: 'pre-wrap' }}>
        <strong>1. TARAFLAR</strong>
        {'\n'}İşbu Sözleşme aşağıdaki taraflar arasında aşağıda belirtilen hüküm ve şartlar çerçevesinde imzalanmıştır.
        {'\n'}<br />
        <strong>‘ALICI’</strong>; (sözleşmede bundan sonra "ALICI" olarak anılacaktır)
        {'\n'}AD-SOYAD:
        {'\n'}ADRES:
        {'\n'}ÜYE NUMARASI:
        {'\n'}E-POSTA:
        {'\n'}TELEFON:

        {'\n'}<br />
        <strong>‘SATICI’</strong>; (sözleşmede bundan sonra "www.pulsepeti.com" olarak anılacaktır)
        {'\n'}Ünvanı: Erdem Kurt www.pulsepeti.com
        {'\n'}Adres: Meşrutiyet Mah. Konur sk. Özsoy İşhanı No: 25 İç Kapı No: 13 Çankaya/Ankara
        {'\n'}Telefon: +90 (533) 2508029
        {'\n'}Eposta: destek@pulsepeti.com
        {'\n'}<br />
        İş bu sözleşmeyi kabul etmekle ALICI, sözleşme konusu siparişi onayladığı takdirde sipariş konusu bedeli ve varsa kargo ücreti, vergi gibi belirtilen ek ücretleri ödeme yükümlülüğü altına gireceğini ve bu konuda bilgilendirildiğini peşinen kabul eder.
        {'\n'}<br />
        <strong>2. SÖZLEŞME KONUSU</strong>
        {'\n'}İşbu sözleşmenin konusu, alıcının, www.pulsepeti.com'dan elektronik ortamda siparişini yaptığı aşağıda nitelikleri ve satış fiyatı belirtilen ürünün satışı ve teslimi ile ilgili olarak 6502 sayılı Tüketicilerin Korunması Hakkındaki Kanun ve Mesafeli Sözleşmeler Yönetmeliği hükümleri gereğince tarafların hak ve yükümlülüklerinin saptanmasıdır.
        {'\n'}<br />
        <strong>3. ÜRÜN</strong>
        {'\n'}İşbu mesafeli satış sözleşmesi konusu ürün, ödeme ve teslim bilgileri aşağıdaki gibidir:
        {'\n'}<br />
        Ürün :{'\n'}<br />
        Ürün numarası :{'\n'}<br />
        Adet :{'\n'}<br />
        Satış fiyatı(+KDV) :{'\n'}<br />
        Kargo Bedeli :{'\n'}<br />
        TOPLAM :{'\n'}<br />
        Ödeme şekli :{'\n'}<br />
        Teslimat Adresi :{'\n'}<br />
        Teslim edilecek kişi :{'\n'}<br />
        Fatura Adresi :{'\n'}<br />
        Sipariş Tarihi :{'\n'}<br />
        {'\n'}<br />
        <strong>4. GENEL HÜKÜMLER</strong>
        {'\n'}<br />4.1 - Alıcı, ERDEM KURT' a ait www.pulsepeti.com  alan adlı internet sitesinde satışa arz edilen mesafeli satış sözleşmesine konu ürünün/ürünlerin www.pulsepeti.com alan adlı internet sitesinde belirtilen ve yukarıda yer alan satış fiyatı, ödeme şekli ve teslimata ilişkin ön bilgileri ve diğer bilgileri okuyup doğru ve eksiksiz şekilde bilgi sahibi olduğunu ve elektronik ortamda satın almaya ilişkin gerekli teyidi verdiğini kabul ve beyan eder.        {'\n'}<br />
        {'\n'}<br />4.2 Mesafeli satış sözleşmesine konu ürün/ürünler, şehir içi teslimlerde 7 gün, şehir dışı teslimlerde 14 gün içinde, her halde yasa ve yönetmeliklerle belirlenen yasal 30 (otuz) günlük süreyi aşmamak koşulu ile her bir ürün için Alıcı'nın yerleşim yerinin uzaklığına bağlı olarak Alıcı veya gösterdiği adresteki kişi/kuruluşa teslim edilir. İşbu teslim süresi ilgili ürüne/ürünlere ilişkin ödemenin yapılması tarihinden itibaren başlayacaktır.
        {'\n'}<br />4.3 Sözleşme konusu ürün, Alıcı'dan başka bir kişi/kuruluşa teslim edilecek ise, teslim edilecek kişi/kuruluşun teslimatı kabul etmemesinden www.pulsepeti.com. sorumlu tutulamaz.
        {'\n'}<br />4.4 Kargo firmasının, ürünü Alıcı'ya teslimi aşamasında karşılaşacağı her türlü sorun nedeniyle, siparişi verilen ürünün Alıcı'ya teslim edilememesinden veya geç teslim edilmesinden dolayı www.pulsepeti.com. sorumlu tutulamaz.
        {'\n'}<br />4.5 www.pulsepeti.com. Sözleşme konusu ürünü eksiksiz, siparişte belirtilen niteliklere uygun teslim etmeyi, her türlü ayıptan arî olarak yasal mevzuat gereklerine göre sağlam, standartlara uygun bir şekilde doğruluk ve dürüstlük esasları dâhilinde edimini ifa etmeyi, hizmet kalitesini koruyup yükseltmeyi, işin ifası sırasında gerekli dikkat ve özeni göstermeyi, ihtiyat ve öngörü ile hareket edeceğini kabul, beyan ve taahhüt eder.
        {'\n'}<br />4.6 Alıcı, ürünü/ürünleri teslim aldığı anda kontrol etmekle ve üründe/ürünlerde kargodan kaynaklanan bir sorun gördüğünde, ürünü/ürünleri kabul etmemekle ve kargo firması yetkilisine tutanak tutturmakla sorumludur.
        {'\n'}<br />4.7 Mesafeli satış sözleşmesine konu ürün/ürünlerin teslimatı için işbu mesafeli satış sözleşmesinin elektronik ortamda teyit edilmiş olması ve ürün/ürünlerin bedelinin Alıcı'nın tercih ettiği ödeme şekli ile ödenmiş olması şarttır. Satın alınan ürünlere ilişkin ödeme kredi kartı veya havale/eft yoluyla peşin olarak, alıcı tarafından gerçekleştirilir. Alıcı satış bedelinin tamamını ve Katma Değer Vergisi'ni (KDV) ödediği anda www.pulsepeti.com.'nin ürünü teslim yükümlülüğü doğacaktır. Herhangi bir nedenle ürünün/ürünlerin bedeli ödenmez veya banka kayıtlarında iptal edilir ise, www.pulsepeti.com. ürünün/ürünlerin teslimi yükümlülüğünden kurtulmuş kabul edilir.
        {'\n'}<br />4.8 Mesafeli satış sözleşmesine konu ürüne/ürünlere ilişkin ödemenin Alıcı tarafından kredi kartı ile yapılması durumunda, Alıcı ile kredi kartı sahibinin ya da ürünün/ürünlerin teslim edileceği kişinin farklı olmasından kaynaklanabilecek olan, kredi kartının yetkisiz kişilerce haksız ve hukuka aykırı olarak kullanılması da dahil olmak üzere türlü hukuki risk, Alıcı'ya aittir. Alıcı, bahsi geçen durumlarda herhangi bir şekilde zarara uğraması halinde www.pulsepeti.com.'den hiçbir talepte bulunmayacağını kabul ve taahhüt eder.
        {'\n'}<br />4.9 Alıcı, Sözleşme konusu ürünün Alıcı veya Alıcı'nın gösterdiği adresteki kişi/kuruluşa tesliminden sonra Alıcı'ya ait kredi kartının yetkisiz kişilerce haksız kullanılması sonucunda sözleşme konusu ürün bedelinin ilgili banka veya finans kuruluşu tarafından www.pulsepeti.com.'ye ödenmemesi halinde, Alıcı Sözleşme konusu ürünü 3 gün içerisinde nakliye gideri Alıcı'ya ait olacak şekilde www.pulsepeti.com.'ye iade edeceğini kabul, beyan ve taahhüt eder.
        {'\n'}<br />4.10 Alıcı tarafından, mesafeli satış sözleşmesine konu ürüne/ürünlere ilişkin ödemenin 'hesaba yatırma' şeklinde yapılması durumunda, ürünün/ürünlerin bedelinin işbu sözleşmede belirtilen şartlar çerçevesinde Alıcı'ya iade edilmesinin gerektiği hallerde kullanılmak üzere kendisine ait bir banka hesabına ait IBAN numarasını doğru ve eksiksiz bir şekilde www.pulsepeti.com.'ye bildirmeyi ve www.pulsepeti.com. tarafından IBAN numarasına iade yapılmasına muvafakat ettiğini kabul ve taahhüt eder.
        {'\n'}<br />4.11 Alıcı ve www.pulsepeti.com. işbu mesafeli satış sözleşmesinin başında bahsedilen yazışma adreslerinin geçerli tebligat adresi olduğunu ve bu adrese yöneltilecek tüm tebligatların geçerli addolunacağını kabul, beyan ve taahhüt eder.
        {'\n'}<br />4.12 Taraflar işbu Sözleşme şartlarının yanı sıra Tüketicilerin Korunması Hakkındaki Kanun ve Mesafeli Sözleşmeler Yönetmeliği hükümlerini kabul ettiklerini ve bu hükümlere uygun hareket edeceklerini kabul, beyan ve taahhüt ederler.
        {'\n'}<br />4.13 18 yaşından küçük kişiler ile ayırt etme gücünden yoksun veya kısıtlı erginler www.pulsepeti.com.'den alışveriş yapamaz.
        {'\n'}<br />4.14 İşbu Sözleşme'nin tüm maddeleri Taraflarca okunmuş ve kabul edilmiş olup, işbu Sözleşme Alıcı tarafından elektronik olarak onaylandığı tarihte yürürlüğe girer.
        {'\n'}<br />
        <strong>5. CAYMA HAKKI</strong>
        {'\n'}<br />5.1 Alıcı, işbu Sözleşme konusu ürünün kendisine veya gösterdiği adresteki kişiye/kuruluşa tesliminden itibaren 14 (on dört) gün içerisinde herhangi bir gerekçe göstermeksizin işbu Sözleşmeden cayma hakkına sahiptir. Alıcı'nın, cayma hakkını kullanabilmesi için, bu süre içinde www.pulsepeti.com.'ye bildirimde bulunması şarttır. Alıcı'nın Cayma hakkının kullanılabilmesi için ürüne zarar gelmemiş olması kaydıyla www.pulsepeti.com.'ye iletişim bilgileri vasıtasıyla yazılı olarak bildirimde bulunmak zorundadır. İşbu bildirim iadeli taahhütlü posta, faks veya e-posta yoluyla olabilir. Alıcı'nın kusurundan kaynaklanan bir nedenle malın değerinde bir azalma olursa veya iade imkânsızlaşırsa Alıcı kusuru oranında www.pulsepeti.com.'nin zararlarını tazmin etmekle yükümlüdür.
        {'\n'}<br />5.2 Cayma hakkı bildiriminin www.pulsepeti.com.'ye ulaşmasını takiben www.pulsepeti.com. bildirimin kendisine ulaştığına ilişkin teyit bilgisini ALICI ile paylaşacak ve bildirimin ulaşma tarihini takiben de 14 (on dört) gün içinde www.pulsepeti.com. cayma hakkına konu ürünün/ürünlerin bedelini ödeme şekline uygun bir şekilde Alıcı'ya iade edecektir.
        {'\n'}<br />5.3 Cayma hakkının kullanımı halinde, cayma hakkının kullanıldığına ilişkin bildirim tarihinden itibaren on (10) gün içinde işbu sözleşmenin 1. Maddesinde bildirilen www.pulsepeti.com.'nin adresine ürünün elden iade edilmesi veya kargo yoluyla gönderilmesi zorunludur. Kargo yoluyla yapılacak iadelerde masraflar alıcıya ait olacaktır. Bu iade işleminde Ürün'ün kutusu, ambalajı, varsa standart aksesuarları ile birlikte eksiksiz ve hasarsız olarak teslim edilmesi gerekmektedir.
        {'\n'}<br />5.4 Cayma hakkı kullanımı mümkün olan hallerde, ALICI, cayma süresi içinde malı, işleyişine, teknik özelliklerine ve kullanım talimatlarına uygun bir şekilde kullanmadığı takdirde meydana gelen değişiklik ve bozulmalardan kanun gereği sorumludur. Buna göre, cayma tarihine kadarki süreçte Ürün'ün kullanım talimatlarına, teknik özelliklerine ve işleyişine uygun bir şekilde kullanılmamasından ötürü değişiklik veya bozulma olursa ALICI cayma hakkını kaybedebilir; SATICI tarafından kabul edildiği hallerde, iade edilecek Ürün bedelinden değişiklik / bozulma kadar indirim yapılır.
        {'\n'}<br />
        <strong>6. CAYMA HAKKININ İSTİSNALARI</strong>
        {'\n'}<br />27.11.2014 tarihli 29188 sayılı Resmi Gazetede yayımlanan MESAFELİ SÖZLEŞMELER YÖNETMELİĞİ uyarınca;
        {'\n'}<br />a. Fiyatı finansal piyasalardaki dalgalanmalara bağlı olarak değişen ve satıcı veya sağlayıcının kontrolünde olmayan mal veya hizmetlere ilişkin sözleşmeler.
        {'\n'}<br />b. Tüketicinin istekleri veya kişisel ihtiyaçları doğrultusunda hazırlanan mallara ilişkin sözleşmeler.
        {'\n'}<br />c. Çabuk bozulabilen veya son kullanma tarihi geçebilecek malların teslimine ilişkin sözleşmeler.
        {'\n'}<br />d. Tesliminden sonra ambalaj, bant, mühür, paket gibi koruyucu unsurları açılmış olan mallardan; iadesi sağlık ve hijyen açısından uygun olmayanların teslimine ilişkin sözleşmeler.

        {'\n'}<br />e. Tesliminden sonra başka ürünlerle karışan ve doğası gereği ayrıştırılması mümkün olmayan mallara ilişkin sözleşmeler.

        {'\n'}<br />f. Malın tesliminden sonra ambalaj, bant, mühür, paket gibi koruyucu unsurları açılmış olması halinde maddi ortamda sunulan kitap, dijital içerik ve bilgisayar sarf malzemelerine ilişkin sözleşmeler.

        {'\n'}<br />g. Abonelik sözleşmesi kapsamında sağlananlar dışında, gazete ve dergi gibi süreli yayınların teslimine ilişkin sözleşmeler.

        {'\n'}<br />h. Belirli bir tarihte veya dönemde yapılması gereken, konaklama, eşya taşıma, araba kiralama, yiyecek-içecek tedariki ve eğlence veya dinlenme amacıyla yapılan boş zamanın değerlendirilmesine ilişkin sözleşmeler.

        {'\n'}<br />i. Elektronik ortamda anında ifa edilen hizmetler veya tüketiciye anında teslim edilen gayrimaddi mallara ilişkin sözleşmeler.
        {'\n'}<br />j. Elektronik ortamda anında ifa edilen hizmetler veya tüketiciye anında teslim edilen gayrimaddi mallara ilişkin sözleşmeler.
        {'\n'}<br />
        <strong>7. BORÇLUNUN TEMERRÜDÜ</strong>
        {'\n'}<br />ALICI, kredi kartı ile yapmış olduğu işlemlerinde temerrüde düşmesi halinde kart sahibi bankanın kendisi ile yapmış olduğu kredi kartı sözleşmesi çerçevesinde faiz ödeyeceğini ve bankaya karşı sorumlu olacağını kabul, beyan ve taahhüt eder. Bu durumda ilgili banka hukuki yollara başvurabilir; doğacak masrafları ve vekâlet ücretini Alıcı'dan talep edebilir ve her koşulda Alıcı'nın borcundan dolayı temerrüde düşmesi halinde, ALICI, borcun gecikmeli ifasından dolayı www.pulsepeti.com.'nin uğradığı zarar ve ziyanını ödeyeceğini kabul, beyan ve taahhüt eder.
        {'\n'}<br />
        <strong>8. YETKİLİ MAHKEME</strong>
        {'\n'}İşbu sözleşmeden doğabilecek uyuşmazlıklarda, T.C. Ticaret Bakanlığı’nca ilan edilen değere kadar Tüketici Hakem Heyetleri ve ALICI’nın veya SATICI’nın yerleşim yerindeki Tüketici Mahkemeleri yetkilidir.
        {'\n'}<br />
        İşbu Sözleşme elektronik ortamda onaylanarak yürürlüğe girmiştir.
      </p>
    </div>
  </>
);

export default DistanceSellingScreen;
