export const addDecimals = (num) => { 
    return (Math.round(num * 100) / 100).toFixed(2);
}

export const updateCart = (state) => {
    //calculate items price
    state.itemsPrice = addDecimals(state.cartItems.reduce((acc, item) => acc + item.price * item.qty, 0));
    //calculate shipping price (If order is over 500 then free, else 30 shipping)
    state.shippingPrice = addDecimals(state.itemsPrice > 500 ? 0 : 30);
    //calculate tax price (18 percent)
    state.taxPrice = addDecimals(Number((0.18 * state.itemsPrice).toFixed(2)));
    //calculate total price
    state.totalPrice = (
        Number(state.itemsPrice) +
        Number(state.shippingPrice) +
        Number(state.taxPrice) 
    ).toFixed(2);
    
    localStorage.setItem('cart', JSON.stringify(state));

    return state;
}