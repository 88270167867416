import { apiSlice } from "./apiSlice";
import { PAYMENT_URL } from "../constants";

export const paymentApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        initializeCheckoutForm: builder.mutation({
            query: (paymentData) => ({
                url: `${PAYMENT_URL}/initialize`,
                method: 'POST',
                body: { ...paymentData },
            }),
        }),
        handlePaymentCallback: builder.mutation({
            query: (callbackData) => ({
                url: `${PAYMENT_URL}/callback`,
                method: 'POST',
                body: { ...callbackData},
            }),
        }),
    }),
});

export const { useInitializeCheckoutFormMutation, useHandlePaymentCallbackMutation } = paymentApiSlice;