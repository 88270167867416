import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const PaymentScreen = () => {
  const location = useLocation();
  const { checkoutFormContent } = location.state || {};
  const hasRefreshed = localStorage.getItem('hasRefreshed');

  useEffect(() => {
    // If there's no refresh flag, set it and trigger a page refresh
    if (!hasRefreshed) {
      localStorage.setItem('hasRefreshed', 'true');
      
      // Delay the refresh by 500ms
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } else if (checkoutFormContent) {
      // Proceed with script injection if not refreshing
      const scriptContent = checkoutFormContent.replace(/^<script[^>]*>/, '').replace(/<\/script>$/, '');
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.textContent = scriptContent;
      document.body.appendChild(script);
      
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [checkoutFormContent]);

  // Reset the refresh flag on component unmount
  useEffect(() => {
    return () => {
      localStorage.removeItem('hasRefreshed');
    };
  }, []);

  if (!checkoutFormContent) {
    return <div>Error: Missing checkout form content</div>;
  }

  return (
    <div>
      <h1>Ödeme</h1>
      <div id="iyzipay-checkout-form" />
    </div>
  );
};

export default PaymentScreen;
