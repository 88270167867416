import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Row, Col, ListGroup, Image, Button, Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { 
  useGetOrderDetailsQuery,
  useDeliverOrderMutation } from '../slices/ordersApiSlice';
import {clearCartItems} from '../slices/cartSlice';

const OrderScreen = () => {

  const dispatch = useDispatch();
  const { id: orderId } = useParams();

  const { 
    data: order, 
    refetch, 
    isLoading, 
    error 
  } = useGetOrderDetailsQuery(orderId);

  const [deliverOrder, { isLoading: loadingDeliver }] = useDeliverOrderMutation();
 
  const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    if (order && order.isPaid) {
      // Clear cart items when the order is paid
      dispatch(clearCartItems());
  
    }
  }, [dispatch, order]);

  
  const deliverOrderHandler = async () => {
    try {
      await deliverOrder(orderId);
      refetch();
      toast.success('Order delivered')
    } catch (err) {
      toast.error(err?.data?.message || err.message);
    }
  }

  return isLoading ? <Loader /> : error ? <Message variant="danger" /> 
  : (
    <>
      <h1>Sipariş {order._id}</h1>
      <Row>
        <Col md={8}>
          <ListGroup variant='flush'>
            <ListGroup.Item>
              <h2>Teslimat</h2>
              <p>
                <strong>Name: </strong> { order.user.name }
              </p>
              <p>
                <strong>Email: </strong> { order.user.email }
              </p>
              <p>
                <strong>Teslimat Adresi: </strong> 
                { order.shippingAddress.address }, {order.shippingAddress.city }
                {' '}{order.shippingAddress.postalCode }, {order.shippingAddress.country} 
              </p>
              <p>
                <strong>Fatura Addresi: </strong> 
                { order.billingAddress.address }, {order.billingAddress.city }
                {' '}{order.billingAddress.postalCode }, {order.billingAddress.country} 
              </p>
              { order.isDelivered ? (
                <Message variant='success'>
                   {order.deliveredAt} Tarihinde Teslim Edildi
                </Message>
              ) : (
                <Message variant='danger'>
                  Teslim Edilmedi
                </Message>
              )}
            </ListGroup.Item>

            <ListGroup.Item>
                <h2>Payment</h2>
                { order.isPaid ? (
                <Message variant='success'>
                   {order.paidAt} Tarihinde Ödendi
                </Message>
                ) : (
                <Message variant='danger'>
                  Ödenmedi
                </Message>
                )}
            </ListGroup.Item>
            <ListGroup.Item>
              <h2>Sipariş İçeriği</h2>
              { order.orderItems.map((item, index) => (
                <ListGroup.Item key={index}>
                  <Row>
                    <Col md={1}>
                      <Image src={item.image} alt={item.name} fluid rounded />
                    </Col>
                    <Col>
                      <Link to={`/product/${item.product}`}>
                        {item.name}
                      </Link>
                    </Col>
                    <Col md={4}>
                      {item.qty} x ${item.price} = ${item.qty * item.price}
                    </Col>
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={4}>
          <Card>
            <ListGroup variant='flush'>
              <ListGroup.Item>
                <h2>Sipariş Özeti</h2>
              </ListGroup.Item>
              <ListGroup.Item>           
                <Row>
                  <Col>Ürünler</Col>
                  <Col>${order.itemsPrice}</Col>
                </Row>
                <Row>
                  <Col>Kargo Ücreti</Col>
                  <Col>${order.shippingPrice}</Col>
                </Row>
                <Row>
                  <Col>Vergiler</Col>
                  <Col>${order.taxPrice}</Col>
                </Row>
                <Row>
                  <Col>Total</Col>
                  <Col>${order.totalPrice}</Col>
                </Row>
              </ListGroup.Item>
              { loadingDeliver && <Loader />}

              { userInfo && userInfo.isAdmin && order.isPaid && !order.isDelivered && (
                <ListGroup.Item>
                  <Button type='button' className='btn-block btn-dark' onClick={deliverOrderHandler}>
                    Teslim Edildi Olarak Değiştir
                  </Button>
                </ListGroup.Item>
              )}
              
            </ListGroup>
          </Card>
        </Col>
      </Row>
    
    </>
  );
}

export default OrderScreen;