import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Badge, Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { FaShoppingCart, FaUser } from 'react-icons/fa';
import { LinkContainer } from 'react-router-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useLogoutMutation } from '../slices/usersApiSlice';
import { logout } from '../slices/authSlice';
import SearchBox from './SearchBox';

const Header = () => {
  const { cartItems } = useSelector((state) => state.cart);
  const { userInfo } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [logoutApiCall] = useLogoutMutation();

  const logoutHandler = async () => {
    try {
      await logoutApiCall().unwrap();
      dispatch(logout());
      navigate('/login');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <header>
      <Navbar bg='dark' variant='dark' expand='md' collapseOnSelect>
        <Container>
          <LinkContainer to='/'>
            <Navbar.Brand href='/' className="brand-text">Pul Sepeti</Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id='basic-navbar-nav'>
{/*             <Nav className='me-auto'>
              <NavDropdown title='Türk Pulları Kataloğu' id='turkey-dropdown'>
                <LinkContainer to='/turkey/subcategory1'>
                  <NavDropdown.Item>Türkiye Cumhuriyeti 1924-2022</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/turkey/subcategory2'>
                  <NavDropdown.Item>Türkiye Büyük Millet Meclisi Hükümeti 1920-1923</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/turkey/subcategory3'>
                  <NavDropdown.Item>Osmanlı İmparatorluğu 1863-1922</NavDropdown.Item>
                </LinkContainer>
                
              </NavDropdown>
              <NavDropdown title='Filatelik Malzemeler' id='world-dropdown'>
                <LinkContainer to='/materials/subcategory1'>
                  <NavDropdown.Item>Pul Albümleri</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/materials/subcategory2'>
                  <NavDropdown.Item>Pul Maşası ve Büyüteç</NavDropdown.Item>
                </LinkContainer>
               
              </NavDropdown>
            </Nav> */}
            <Nav className='ms-auto'>
              <SearchBox />
              <LinkContainer to='/cart'>
                <Nav.Link>
                  <FaShoppingCart /> Sepetim
                  {cartItems.length > 0 && (
                    <Badge pill bg='success' style={{ marginLeft: '5px' }}>
                      {cartItems.reduce((a, c) => a + c.qty, 0)}
                    </Badge>
                  )}
                </Nav.Link>
              </LinkContainer>
              {userInfo ? (
                <NavDropdown title={userInfo.name} id='username'>
                  <LinkContainer to='profile'>
                    <NavDropdown.Item>Profil</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Item onClick={logoutHandler}>
                    Çıkış Yap
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <LinkContainer to='/login'>
                  <Nav.Link>
                    <FaUser /> Giriş Yap
                  </Nav.Link>
                </LinkContainer>
              )}
              {userInfo && userInfo.isAdmin && (
                <NavDropdown title='Admin' id='adminmenu'>
                  <LinkContainer to='/admin/productlist'>
                    <NavDropdown.Item>Products</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/userlist'>
                    <NavDropdown.Item>Users</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/orderlist'>
                    <NavDropdown.Item>Orders</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
