import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useGetUserAddressesQuery, useCreateUserAddressMutation } from '../slices/usersApiSlice';
import { saveShippingAddress, saveBillingAddress } from '../slices/cartSlice';
import FormContainer from '../components/FormContainer';
import CheckoutSteps from '../components/CheckoutSteps';
import { toast } from 'react-toastify';

const ShippingScreen = () => {
  const { data: addresses, error, isLoading, refetch } = useGetUserAddressesQuery();
  const [createUserAddress] = useCreateUserAddressMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [validated, setValidated] = useState(false);


  const [selectedAddress, setSelectedAddress] = useState({
    addressName: '',
    address: '',
    city: '',
    postalCode: '',
    country: '',
    phoneNumber: '',
    identityNumber: '',
  });

  const [billingAddress, setBillingAddress] = useState({
    addressName: '',
    address: '',
    city: '',
    postalCode: '',
    phoneNumber: '',
    identityNumber: '',
    country: '',
    firmName: '',
    taxOffice: '',
    taxNo: '',
  });
  const [isExistingAddress, setIsExistingAddress] = useState(false);
  const [isSameAsShipping, setIsSameAsShipping] = useState(true);
  const [isBillingExistingAddress, setIsBillingExistingAddress] = useState(true);
  const [billingType, setBillingType] = useState('Bireysel'); // Default to "Bireysel"


  useEffect(() => {
    refetch();
  }, [refetch]);

  	
  const validateForm = (address) => {

  
    const fieldsToValidate = Object.keys(address);
  
    return fieldsToValidate.every((field) => {
      if (typeof address[field] === 'string') {
        return address[field].trim().length > 0;
      } else if (typeof address[field] === 'number') {
        return !isNaN(address[field]) && address[field] > 0;
      }
      return true; // For other types or if field is valid
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setValidated(true);

    if (!validateForm(selectedAddress)) {
      toast.error('Lütfen tüm zorunlu alanları doldurun.');
      return;
    }

    console.log('Selected Address:', selectedAddress);
    console.log('Billing Address:', billingAddress);
    // Handle form submission
    try{
      if(!isExistingAddress){
      await createUserAddress(selectedAddress);
    }
    if(!isSameAsShipping && !isBillingExistingAddress){
      await createUserAddress(billingAddress);
    }
    dispatch(saveShippingAddress(selectedAddress));
    if(!isSameAsShipping){
      dispatch(saveBillingAddress(billingAddress));
    } else {
      dispatch(saveBillingAddress(selectedAddress));
    }
    navigate('/placeorder'); // Example navigation to next step
  }catch(error){
    toast.error("Adres kaydedilirken bir hata oluştu.");
  }};

  const handleAddressChange = (e, setAddress, setExistingAddress) => {
    const selected = addresses.find((addr) => addr.addressName === e.target.value);
    if(selected){
      setExistingAddress(true);
      setAddress(selected);
    }else{
      setExistingAddress(false);
      setAddress({
        addressName: '',
        address: '',
        city: '',
        postalCode: '',
        country: '',
        phoneNumber: '',
        identityNumber: '',
        firmName: '',
        taxOffice: '',
        taxNo: '',
      });
    }
  };
  

  return (
    <FormContainer>
      <CheckoutSteps step1 step2 />
      <h1>Teslimat Bilgileri</h1>
      <Form noValidate onSubmit={submitHandler}>
        {isLoading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>{error.message}</p>
        ) : addresses && addresses.length > 0 && (
          <Form.Group controlId="selectAddressName" className="my-2">
            <Form.Label>Teslimat Adresi Seç</Form.Label>
            <Form.Control
              as="select"
              value={selectedAddress.addressName}
              onChange={(e) => handleAddressChange(e, setSelectedAddress, setIsExistingAddress)}
            >
              <option value="">Yeni Teslimat Adresi</option>
              {addresses.map((addr) => (
                <option key={addr._id} value={addr.addressName}>
                  {addr.addressName}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        )}

        <Form.Group controlId="addressName" className="my-2">
          <Form.Label>Adres Başlığı</Form.Label>
          <Form.Control
            type="text"
            
            value={selectedAddress.addressName}
            onChange={(e) =>
              setSelectedAddress({ ...selectedAddress, addressName: e.target.value })
            }
            isInvalid={validated && selectedAddress.addressName.trim() === ''}
            disabled={isExistingAddress}
          />
            <Form.Control.Feedback type="invalid">
              Bu alan boş bırakılamaz.
            </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="address" className="my-2">
          <Form.Label>
            Adres <span style={{ color: 'red' }}>*</span>
          </Form.Label>
          <Form.Control
            type="text"
            value={selectedAddress.address}
            onChange={(e) =>
              setSelectedAddress({ ...selectedAddress, address: e.target.value })
            }
            isInvalid={validated && selectedAddress.address.trim() === ''}
            disabled={isExistingAddress}
          />
            <Form.Control.Feedback type="invalid">
              Bu alan boş bırakılamaz.
            </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="city" className="my-2">
          <Form.Label>Şehir</Form.Label>
          <Form.Control
            type="text"
            value={selectedAddress.city}
            onChange={(e) =>
              setSelectedAddress({ ...selectedAddress, city: e.target.value })
            }
            isInvalid={validated && selectedAddress.city.trim() === ''}
            disabled={isExistingAddress}
          />
          <Form.Control.Feedback type="invalid">
              Bu alan boş bırakılamaz.
            </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="phoneNumber" className="my-2">
  <Form.Label>Telefon Numarası</Form.Label>
  <Form.Control
  type="text"
  value={selectedAddress.phoneNumber}
  onChange={(e) => setSelectedAddress({ ...selectedAddress, phoneNumber: e.target.value })}
  isInvalid={validated && !/^\d{10}$/.test(selectedAddress.phoneNumber)}
  placeholder="Örnek: 5xxxxxxxxx" // Add placeholder
  disabled={isExistingAddress}
/>
<Form.Control.Feedback type="invalid">
  Telefon numarası 10 haneli olmalıdır (örnek: 5xxxxxxxxx).
</Form.Control.Feedback>
</Form.Group>
        <Form.Group controlId="identityNumber" className="my-2">
          <Form.Label>Kimlik Numarası</Form.Label>
          <Form.Control
            type="text"
            value={selectedAddress.identityNumber}
            onChange={(e) =>
              setSelectedAddress({ ...selectedAddress, identityNumber: e.target.value })
            }
            disabled={isExistingAddress}
            required={!isExistingAddress}
          />
          </Form.Group>
        <Form.Group controlId="postalCode" className="my-2">
          <Form.Label>Posta Kodu</Form.Label>
          <Form.Control
            type="text"
            value={selectedAddress.postalCode}
            onChange={(e) =>
              setSelectedAddress({ ...selectedAddress, postalCode: e.target.value })
            }
            disabled={isExistingAddress}
            required={!isExistingAddress}
          />
        </Form.Group>
        <Form.Group controlId="country" className="my-2">
          <Form.Label>Ülke</Form.Label>
          <Form.Control
            type="text"
            value={selectedAddress.country}
            onChange={(e) =>
              setSelectedAddress({ ...selectedAddress, country: e.target.value })
            }
            disabled={isExistingAddress}
            required={!isExistingAddress}
          />
        </Form.Group>

        <Form.Group controlId="billingSameAsShipping" className="my-2">
          <Form.Check
            type="checkbox"
            label="Fatura adresim teslimat adresim ile aynı olsun"
            checked={isSameAsShipping}
            onChange={(e) => {
              const isChecked = e.target.checked;
              setIsSameAsShipping(isChecked);
        
              if (!isChecked) {
                // Reset the billing form to a new address when unchecked
                setBillingAddress({
                  addressName: '',
                  address: '',
                  city: '',
                  postalCode: '',
                  phoneNumber: '',
                  identityNumber: '',
                  country: '',
                  firmName: '',
                  taxOffice: '',
                  taxNo: '',
                });
                setIsBillingExistingAddress(false); // Ensure form fields are enabled
              }
            }}
            />
        </Form.Group>
        {!isSameAsShipping && (
          <>
            <h2>Fatura Bilgileri</h2>
            {addresses && addresses.length > 0 && (
            <Form.Group controlId="selectBillingAddressName" className="my-2">
              <Form.Label>Fatura Adresi Seç</Form.Label>
                <Form.Control
                  as='select'
                  value={billingAddress.addressName}
                  onChange={(e) => handleAddressChange(e, setBillingAddress, setIsBillingExistingAddress)}
                  >
                  <option value="">Yeni Fatura Adresi</option>
                  {addresses.map((addr) => (
                    <option key={addr._id} value={addr.addressName}>
                      {addr.addressName}
                    </option>
                    ))}
                  </Form.Control>
                  </Form.Group> 
            )}

             <Form.Group controlId="billingAddressName" className="my-2">
              <Form.Label>Adres Başlığı</Form.Label>
              <Form.Control
                type="text"
                value={billingAddress.addressName}
                onChange={(e) =>
                  setBillingAddress({ ...billingAddress, addressName: e.target.value })
                }
                disabled={isBillingExistingAddress}
                required={!isBillingExistingAddress}
              />
            </Form.Group>
            <Form.Group controlId="billingAddress" className="my-2">
              <Form.Label>Adres</Form.Label>
              <Form.Control
                type="text"
                value={billingAddress.address}
                onChange={(e) =>
                  setBillingAddress({ ...billingAddress, address: e.target.value })
                }
                disabled={isBillingExistingAddress}
                required={!isBillingExistingAddress}
              />
            </Form.Group>
            <Form.Group controlId="billingCity" className="my-2">
              <Form.Label>Şehir</Form.Label>
              <Form.Control
                type="text"
                value={billingAddress.city}
                onChange={(e) =>
                  setBillingAddress({ ...billingAddress, city: e.target.value })
                }
                disabled={isBillingExistingAddress}
                required={!isBillingExistingAddress}
              />
            </Form.Group>
            <Form.Group controlId="billingPhoneNumber" className="my-2">
  <Form.Label>Telefon Numarası</Form.Label>
  <Form.Control
  type="text"
  value={billingAddress.phoneNumber}
  onChange={(e) => setBillingAddress({ ...billingAddress, phoneNumber: e.target.value })}
  isInvalid={validated && !/^\d{10}$/.test(billingAddress.phoneNumber)}
  placeholder="Örnek: 5xxxxxxxxx" // Add placeholder
  disabled={isBillingExistingAddress}
/>
<Form.Control.Feedback type="invalid">
  Telefon numarası 10 haneli olmalıdır (örnek: 5xxxxxxxxx).
</Form.Control.Feedback>
</Form.Group>
        <Form.Group controlId="billingIdentityNumber" className="my-2">
          <Form.Label>Kimlik Numarası</Form.Label>
          <Form.Control
            type="text"
            value={billingAddress.identityNumber}
            onChange={(e) =>
              setBillingAddress({ ...billingAddress, identityNumber: e.target.value })
            }
            disabled={isBillingExistingAddress}
            required={!isBillingExistingAddress}
          />
          </Form.Group>
            <Form.Group controlId="billingPostalCode" className="my-2">
              <Form.Label>Posta Kodu</Form.Label>
              <Form.Control
                type="text"
                value={billingAddress.postalCode}
                onChange={(e) =>
                  setBillingAddress({ ...billingAddress, postalCode: e.target.value })
                }
                disabled={isBillingExistingAddress}
                required={!isBillingExistingAddress}
              />
            </Form.Group>
            <Form.Group controlId="billingCountry" className="my-2">
              <Form.Label>Ülke</Form.Label>
              <Form.Control
                type="text"
                value={billingAddress.country}
                onChange={(e) =>
                  setBillingAddress({ ...billingAddress, country: e.target.value })
                }
                disabled={isBillingExistingAddress}
                required={!isBillingExistingAddress}
              />
            </Form.Group>
            	
            {/* Billing Type Selection */}
            <Form.Group controlId="billingType" className="my-2">
              <Form.Check
                inline
                label="Bireysel"
                type="radio"
                name="billingType"
                checked={billingType === 'Bireysel'}
                onChange={() => setBillingType('Bireysel')}
              />
              <Form.Check
                inline
                label="Kurumsal"
                type="radio"
                name="billingType"
                checked={billingType === 'Kurumsal'}
                onChange={() => setBillingType('Kurumsal')}
              />
            </Form.Group>
            {/* Billing Address Form Groups */}
                        {/* Additional Corporate Fields for Kurumsal */}
                        {billingType === 'Kurumsal' && (
              <>
                <Form.Group controlId="firmName" className="my-2">
                  <Form.Label>Firma Adı</Form.Label>
                  <Form.Control
                    type="text"
                    value={billingAddress.firmName}
                    onChange={(e) =>
                      setBillingAddress({ ...billingAddress, firmName: e.target.value })
                    }
                    disabled={isBillingExistingAddress}
                  />
                </Form.Group>
                <Form.Group controlId="taxOffice" className="my-2">
                  <Form.Label>Vergi Dairesi</Form.Label>
                  <Form.Control
                    type="text"
                    value={billingAddress.taxOffice}
                    onChange={(e) =>
                      setBillingAddress({ ...billingAddress, taxOffice: e.target.value })
                    }
                    disabled={isBillingExistingAddress}
                  />
                </Form.Group>
                <Form.Group controlId="taxNo" className="my-2">
                  <Form.Label>Vergi Numarası</Form.Label>
                  <Form.Control
                    type="text"
                    value={billingAddress.taxNo}
                    onChange={(e) =>
                      setBillingAddress({ ...billingAddress, taxNo: e.target.value })
                    }
                    disabled={isBillingExistingAddress}
                  />
                </Form.Group>
              </>
            )}
          </>
        )}
        <Button type="submit" variant="primary" className="my-2 btn-dark">
          Kaydet ve İlerle
        </Button>
      </Form>
    </FormContainer>
  );
};

export default ShippingScreen;
