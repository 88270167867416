import { useState, useEffect} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form, Button, Row, Col, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import {useRegisterMutation } from '../slices/usersApiSlice';
import { setCredentials } from '../slices/authSlice';
import { toast } from 'react-toastify';




const RegisterScreen = () => {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  const [showUserContractModal, setShowUserContractModal] = useState(false); // State for modal visibility
  const [showKVKKModal, setShowKVKKModal] = useState(false); // State for modal visibility



  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [register, { isLoading }] = useRegisterMutation();

  const { userInfo } = useSelector((state) => state.auth);
  console.log(userInfo);

  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const redirect = sp.get('redirect') || '/';

  useEffect(() => {
    if(userInfo){
      navigate(redirect);
    }
  }, [userInfo, redirect, navigate]);

  const handleShowUserContractModal = () => setShowUserContractModal(true);
  const handleShowUserContractCloseModal = () => setShowUserContractModal(false);

  const handleShowKVKKModal = () => setShowKVKKModal(true);
  const handleShowKVKKCloseModal = () => setShowKVKKModal(false);

  const isPasswordValid = (password) => {
    const minLength = 8;
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    return password.length >= minLength && hasUppercase && hasLowercase;
  };

  const submitHandler = async (e) => {
    e.preventDefault();

      // Custom validation for name
    if (!name.trim()) {
      toast.error("Lütfen bir isim giriniz.");
    return;
    }

    // Custom validation for email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
    toast.error("Geçerli bir e-posta adresi giriniz.");
    return;
    }
  
    if(!isTermsAccepted) {
      toast.error(
        "Üyelik sözleşmesini ve Kişisel verilerin işlenmesine ilişkin Aydınlatma Metnini okumanız ve kabul etmeniz gerekiyor."
      );
      return;
    }

    if (!isPasswordValid(password)) {
      toast.error(
        "Şifre en az 8 karakter uzunluğunda olmalı, büyük ve küçük harf içermelidir."
      );
      return;
    }

    if(password !== confirmPassword) {
      toast.error('Şifreler Eşleşmiyor');
      return;
    }else{
      try {
        const res = await register({name, email, password}).unwrap();
        dispatch(setCredentials({...res }));
        console.log(userInfo);
        navigate(redirect);
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  return (
    <FormContainer>
      <h1>Üye Ol</h1>
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="name" className="my-3">
          <Form.Label>Ad ve Soyad</Form.Label>
          <Form.Control
            type="text"
            placeholder="Adınızı ve Soyadınızı Girin"
            value={name}
            onChange={(e) => setName(e.target.value)}>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="email" className="my-3">
          <Form.Label>E-mail Adresi</Form.Label>
          <Form.Control
            type="email"
            placeholder="E-mail Adresinizi Girin"
            value={email}
            onChange={(e) => setEmail(e.target.value)}>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="password" className="my-3">
          <Form.Label>Şifre</Form.Label>
          <Form.Control
            type="password"
            placeholder="Şifrenizi Girin"
            value={password}
            onChange={(e) => setPassword(e.target.value)}>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="confirmPassword" className="my-3">
          <Form.Label>Şifreyi Doğrula</Form.Label>
          <Form.Control
            type="password"
            placeholder="Şifrenizi Tekrar Girin"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="termsCheckbox" className="my-3">
          <Form.Check
            type="checkbox"
            label={
              <span>
                <span onClick={(e) => { 
                  e.preventDefault(); // Prevent checkbox change
                  handleShowUserContractModal();  }} style={{ fontWeight: "bold", fontStyle: "italic", cursor: "pointer" }}>
                  Üyelik sözleşmesini
                </span>{" "}
                ve <span onClick={(e) => { 
                  e.preventDefault(); // Prevent checkbox change
                  handleShowKVKKModal();  }} style={{ fontWeight: "bold", fontStyle: "italic", cursor: "pointer" }}>
                KVKK Aydınlatma Metnini
                </span> okudum ve kabul
                ediyorum.
              </span>
            }
            checked={isTermsAccepted}
            onChange={(e) => setIsTermsAccepted(e.target.checked)}
          />
        </Form.Group>

        <Button type="submit" variant="primary" className="mt-2 btn-dark" disabled={ isLoading }>
            Üye Ol
        </Button>

        {isLoading ?? <Loader />}
      </Form>

      <Row className="py-3">
        <Col>
          Üyeliğiniz Var Mı? <Link to={redirect ? `/login?redirect=${redirect}` : '/login' }>Giriş Yap</Link>
        </Col>

      </Row>
      {/* Modal Component */}
      <Modal show={showUserContractModal} onHide={handleShowUserContractCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Üyelik Sözleşmesi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
<p>
Bu alışveriş sitesini kullanan ve alışveriş yapan müşterilerimiz aşağıdaki şartları kabul etmiş varsayılmaktadır:
</p>

<p>
Sitemizdeki web sayfaları ve ona bağlı tüm sayfalar (‘site’) www.pulsepeti.com adresindeki Erdem Kurt firmasına (‘Firma) aittir ve onun tarafından işletilir. Sizler (‘Kullanıcı’) sitede sunulan tüm hizmetleri kullanırken aşağıdaki şartlara tabi olduğunuzu, sitedeki hizmetten yararlanmakla ve kullanmaya devam etmekle; Bağlı olduğunuz yasalara göre sözleşme imzalama hakkına, yetkisine ve hukuki ehliyetine sahip ve 18 yaşın üzerinde olduğunuzu, bu sözleşmeyi okuduğunuzu, anladığınızı ve sözleşmede yazan şartlarla bağlı olduğunuzu kabul etmiş sayılırsınız.
</p>

<p>
İşbu sözleşme süresiz olmakla, taraflara sözleşme konusu site ile ilgili hak ve yükümlülükler yükler ve taraflar işbu sözleşmeyi online veya yazık olarak kabul ettiklerinde/onayladıklarında bahsi geçen hak ve yükümlülükleri eksiksiz, doğru, zamanında, işbu sözleşmede talep edilen şartlar dâhilinde yerine getireceklerini beyan ve taahhüt ederler.
</p>


<p>
<strong>1. SORUMLULUKLAR</strong>
</p>

<p>
Firma, fiyatlar ve sunulan ürün ve hizmetler üzerinde değişiklik yapma hakkını her zaman saklı tutar.
</p>

<p>
Firma, üyenin sözleşme konusu hizmetlerden, teknik arızalar dışında yararlandırılacağını kabul ve taahhüt eder.
</p>

<p>
Kullanıcı, sitenin kullanımında tersine mühendislik yapmayacağını ya da bunların kaynak kodunu bulmak veya elde etmek amacına yönelik herhangi bir başka işlemde bulunmayacağını aksi halde ve 3. Kişiler nezdinde doğacak zararlardan sorumlu olacağını, hakkında hukuki ve cezai işlem yapılacağını peşinen kabul eder.
</p>

<p>
Kullanıcı, siteye üye olurken vermiş olduğu eksik ve yanlış bilgi dolayısıyla uğrayacağı zararlardan sadece kendisinin sorumlu olacağını, yanlış bilgi vermesi durumunda ve işbu sözleşmenin Üye tarafından ihlali halinde, firmanın tek taraflı olarak herhangi bir ihbara ve ihtara ihtiyaç duymaksızın üyeliğini sona erebileceğini kabul eder.
</p>

<p>
Firma tarafından internet sitesinin iyileştirilmesi, geliştirilmesine yönelik olarak ve/veya yasal mevzuat çerçevesinde siteye erişmek için kullanılan Internet servis sağlayıcısının adı ve Internet Protokol (IP) adresi, Siteye erişilen tarih ve saat, sitede bulunulan sırada erişilen sayfalar ve siteye doğrudan bağlanılmasını sağlayan Web sitesinin Internet adresi gibi birtakım bilgiler toplanabilir. Kullanıcı, bu bilgilerin toplanmasını kabul eder.
</p>

<p>
Kullanıcı, site içindeki faaliyetlerinde, sitenin herhangi bir bölümünde veya iletişimlerinde genel ahlaka ve adaba aykırı, kanuna aykırı, 3. Kişilerin haklarını zedeleyen, yanıltıcı, saldırgan, müstehcen, pornografik, kişilik haklarını zedeleyen, telif haklarına aykırı, yasa dışı faaliyetleri teşvik eden içerikler üretmeyeceğini, paylaşmayacağını kabul eder. Aksi halde oluşacak zarardan tamamen kendisi sorumludur ve bu durumda ‘Site’ yetkilileri, bu tür hesapları askıya alabilir, sona erdirebilir, yasal süreç başlatma hakkını saklı tutar. Bu sebeple yargı mercilerinden etkinlik veya kullanıcı hesapları ile ilgili bilgi talepleri gelirse, mercilerle bu bilgileri paylaşma hakkını saklı tutar.
</p>

<p>
Sitenin üyelerinin birbirleri veya üçüncü şahıslarla olan ilişkileri kendi sorumluluğundadır.
</p>

<p>
<strong>2. FİKRİ MÜLKİYET HAKLARI</strong>
</p>

<p>
2.1. İşbu Site’de yer alan ünvan, işletme adı, marka, patent, logo, tasarım, bilgi ve yöntem gibi tescilli veya tescilsiz tüm fikri mülkiyet hakları site işleteni ve sahibi firmaya veya belirtilen ilgilisine ait olup, ulusal ve uluslararası hukukun koruması altındadır. İşbu Site’nin ziyaret edilmesi veya bu Site’deki hizmetlerden yararlanılması söz konusu fikri mülkiyet hakları konusunda hiçbir hak vermez.
</p>

<p>
2.2. Site’de yer alan bilgiler hiçbir şekilde çoğaltılamaz, yayınlanamaz, kopyalanamaz, sunulamaz ve/veya aktarılamaz. Site’nin bütünü veya bir kısmı diğer bir internet sitesinde izinsiz olarak kullanılamaz. Böyle bir ihlal durumunda, kullanıcı,üçüncü kişilerin uğradıkları zararlardan dolayı firmadan talep edilen tazminat miktarını ve mahkeme masrafları ve avukatlık ücreti de dahil ancak bununla sınırlı olmamak üzere diğer her türlü yükümlülükleri karşılamakla sorumlu olacaklardır.
</p>

<p>
<strong>3. GİZLİ BİLGİ</strong>
</p>

<p>
3.1. Firma, site üzerinden kullanıcıların ilettiği kişisel bilgileri 3. Kişilere açıklamayacaktır. Bu kişisel bilgiler; kişi adı-soyadı, adresi, telefon numarası, cep telefonu, e-posta adresi gibi Kullanıcı’yı tanımlamaya yönelik her türlü diğer bilgiyi içermekte olup, kısaca ‘Gizli Bilgiler’ olarak anılacaktır.
</p>

<p>
3.2. Kullanıcı, tanıtım, reklam, kampanya, promosyon, duyuru vb. pazarlama faaliyetleri kapsamında kullanılması ile sınırlı olmak üzere, Site’nin sahibi olan firmanın kendisine ait iletişim, portföy durumu ve demografik bilgilerini iştirakleri ya da bağlı bulunduğu grup şirketleri ile paylaşmasına, kendisi veya iştiraklerine yönelik bu bağlamda elektronik ileti almaya onay verdiğini kabul ve beyan eder. Bu kişisel bilgilerfirma bünyesindemüşteri profili belirlemek, müşteri profiline uygun promosyon ve kampanyalar sunmak ve istatistiksel çalışmalar yapmak amacıyla kullanılabilecektir.
</p>

<p>
3.3.Kullanıcı, işbu sözleşme ile vermiş olduğu onayı, hiçbir gerekçe açıklamaksızın iptal etmek hakkını sahiptir. İptal işlemini firma, derhal işleme alıp, 3 (üç) işgünü içerisinde kullanıcıyı elektronik ileti almaktan imtina eder.
</p>

<p>
3.4.Gizli Bilgiler, ancak resmi makamlarca usulü dairesinde bu bilgilerin talep edilmesi halinde ve yürürlükteki emredici mevzuat hükümleri gereğince resmi makamlara açıklama yapılmasının zorunlu olduğu durumlarda resmi makamlara açıklanabilecektir.
</p>

<p>
<strong>4. GARANTİ VERMEME:</strong>
</p>

<p>
İŞBU SÖZLEŞME MADDESİ UYGULANABİLİR KANUNUN İZİN VERDİĞİ AZAMİ ÖLÇÜDE GEÇERLİ OLACAKTIR. FİRMA TARAFINDAN SUNULAN HİZMETLER "OLDUĞU GİBİ” VE "MÜMKÜN OLDUĞU” TEMELDE SUNULMAKTA VE PAZARLANABİLİRLİK, BELİRLİ BİR AMACA UYGUNLUK VEYA İHLAL ETMEME KONUSUNDA TÜM ZIMNİ GARANTİLER DE DÂHİL OLMAK ÜZERE HİZMETLER VEYA UYGULAMA İLE İLGİLİ OLARAK (BUNLARDA YER ALAN TÜM BİLGİLER DÂHİL) SARİH VEYA ZIMNİ, KANUNİ VEYA BAŞKA BİR NİTELİKTE HİÇBİR GARANTİDE BULUNMAMAKTADIR.
</p>

<p>
<strong>5. KAYIT VE GÜVENLİK</strong>
</p>

<p>
Kullanıcı, doğru, eksiksiz ve güncel kayıt bilgilerini vermek zorundadır. Aksi halde bu Sözleşme ihlal edilmiş sayılacak ve Kullanıcı bilgilendirilmeksizin hesap kapatılabilecektir.
</p>

<p>
Kullanıcı, site ve üçüncü taraf sitelerdeki şifre ve hesap güvenliğinden kendisi sorumludur. Aksi halde oluşacak veri kayıplarından ve güvenlik ihlallerinden veya donanım ve cihazların zarar görmesinden Firmasorumlu tutulamaz.
</p>

<p>
<strong>6. MÜCBİR SEBEP</strong>
</p>

<p>
Tarafların kontrolünde olmayan; tabii afetler, yangın, patlamalar, iç savaşlar, savaşlar, ayaklanmalar, halk hareketleri, seferberlik ilanı, grev, lokavt ve salgın hastalıklar, altyapı ve internet arızaları, elektrik kesintisi gibi sebeplerden (aşağıda birlikte "Mücbir Sebep” olarak anılacaktır.) dolayı sözleşmeden doğan yükümlülükler taraflarca ifa edilemez hale gelirse, taraflar bundan sorumlu değildir. Bu sürede Taraflar’ın işbu Sözleşme’den doğan hak ve yükümlülükleri askıya alınır.
</p>

<p>
<strong>7. SÖZLEŞMENİN BÜTÜNLÜĞÜ VE UYGULANABİLİRLİK</strong>
</p>

<p>
İşbu sözleşme şartlarından biri, kısmen veya tamamen geçersiz hale gelirse, sözleşmenin geri kalanı geçerliliğini korumaya devam eder.
</p>

<p>
<strong>8. SÖZLEŞMEDE YAPILACAK DEĞİŞİKLİKLER</strong> 
</p>

<p>
Firma, dilediği zaman sitede sunulan hizmetleri ve işbu sözleşme şartlarını kısmen veya tamamen değiştirebilir. Değişiklikler sitede yayınlandığı tarihten itibaren geçerli olacaktır. Değişiklikleri takip etmek Kullanıcı’nın sorumluluğundadır. Kullanıcı, sunulan hizmetlerden yararlanmaya devam etmekle bu değişiklikleri de kabul etmiş sayılır.
</p>

<p>
<strong>9. TEBLİGAT</strong>
</p>

<p>
İşbu Sözleşme ile ilgili taraflara gönderilecek olan tüm bildirimler, Firma’nınbilinen e.posta adresi ve kullanıcının üyelik formunda belirttiği e.posta adresi vasıtasıyla yapılacaktır. Kullanıcı, üye olurken belirttiği adresin geçerli tebligat adresi olduğunu, değişmesi durumunda 5 gün içinde yazılı olarak diğer tarafa bildireceğini, aksi halde bu adrese yapılacak tebligatların geçerli sayılacağını kabul eder.
</p>

<p>
<strong>10. DELİL SÖZLEŞMESİ</strong>
</p>

<p>
Taraflar arasında işbu sözleşme ile ilgili işlemler için çıkabilecek her türlü uyuşmazlıklarda Taraflar’ın defter, kayıt ve belgeleri ile ve bilgisayar kayıtları ve faks kayıtları 6100 sayılı Hukuk Muhakemeleri Kanunu uyarınca delil olarak kabul edilecek olup, kullanıcı bu kayıtlara itiraz etmeyeceğini kabul eder.
</p>

<p>
<strong>11. UYUŞMAZLIKLARIN ÇÖZÜMÜ</strong>
</p>

<p>
İşbu Sözleşme’nin uygulanmasından veya yorumlanmasından doğacak her türlü uyuşmazlığın çözümünde İstanbul (Merkez) Adliyesi Mahkemeleri ve İcra Daireleri yetkilidir.
</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleShowUserContractCloseModal}>
            Kapat
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Component */}
      <Modal show={showKVKKModal} onHide={handleShowKVKKCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>KVKK Aydınlatma Metni</Modal.Title>
        </Modal.Header>
        <Modal.Body>

<p>
<strong>KİŞİSEL VERİLERİN KORUNMASI HAKKINDA BİLGİLENDİRME</strong>

</p>

<p>
Gerek web sitemizi kullanırken, gerekse başka yollarla tarafımıza iletmiş olduğunuz kişisel bilgilerinizin güvenliğinin sağlanmasına son derece önem vermektedir. 6698 Sayılı "Kişisel Verilerin Korunması Kanunu” yürürlüğe girmiştir. Anılan mevzuat ve bu mevzuatta belirtilen bir takım tanımlar hakkında sizi bilgilendirmek isteriz:
</p>

<p>
Kişisel veri: Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgiyi,
</p>

<p>
Kişisel verilerin İşlenmesi :Kişisel verilerin tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla elde edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi, değiştirilmesi, yeniden düzenlenmesi, açıklanması, aktarılması, devralınması, elde edilebilir hâle getirilmesi, sınıflandırılması ya da kullanılmasının engellenmesi gibi veriler üzerinde gerçekleştirilen her türlü işlemi,
</p>

<p>
Veri işleyen :Veri sorumlusunun verdiği yetkiye dayanarak onun adına kişisel verileri işleyen gerçek veya tüzel kişiyi,
</p>

<p>
Veri kayıt sistemi : Kişisel verilerin belirli kriterlere göre yapılandırılarak işlendiği kayıt sistemini,
</p>

<p>
Veri sorumlusu : Kişisel verilerin işleme amaçlarını ve vasıtalarını belirleyen, veri kayıt sisteminin kurulmasından ve yönetilmesinden sorumlu olan gerçek veya tüzel kişiyi ifade eder.
</p>

<p>
<strong>1. Kişisel Verilerin Korunması ve Rıza Metninin Amacı ve Şirketimizin Veri Sorumlusu Konumu:</strong>
</p>

<p>
Alışveriş sitemizin müşterilere ilişkin kişisel veriler bakımından 6698 sayılı Kişisel Verilerin Korunması Kanunu ("Kanun”) kapsamında "veri sorumlusu” sıfatına sahip olup işbu Kişisel Verilerin Korunması ve Rıza Metni ile söz konusu Kanun uyarınca müşterilerin alışveriş sitemiz tarafından gerçekleştirilen kişisel veri işleme faaliyetleri hakkında aydınlatılması ve aşağıda 3. maddede belirtilen durumlar için açık rızalarının temini hedeflenmektedir.
</p>

<p>
<strong>2. Müşterilerin Açık Rızası Doğrultusunda İşlenecek Kişisel Veriler ve İşleme Amaçları:</strong>
</p>

<p>
Kanun’un 5/2 ile 6/3 maddesinde yer alan kişisel veri işleme şartlarının karşılanamadığı aşağıdaki durumlar için Alışveriş sitemiz tarafından kişisel verilerin işlenebilmesi için müşterilerin açık rızasının alınması gerekmektedir.
</p>

<p>
<strong>3. Müşterilere Ait Kişisel Verilerin İşlenme Amacı:</strong>
</p>

<p>
Müşterilere ait kişisel veriler aşağıda ve Kanun’un 5. ve 6. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları çerçevesinde işlenmektedir. Müşterilere ait kişisel veriler;
</p>

<p>
Alışveriş sitemiz tarafından sunulan ürün ve hizmetlerden ilgili kişileri faydalandırmak için gerekli çalışmaların iş birimleri tarafından yapılması ve ilgili iş süreçlerinin yürütülmesi,
</p>

<p>
Alışveriş sitemiz tarafından yürütülen ticari faaliyetlerin gerçekleştirilmesi için ilgili iş birimleri tarafından gerekli çalışmaların yapılması ve buna bağlı iş süreçlerinin yürütülmesi,
</p>

<p>
Alışveriş sitemizin ticari ve/veya iş stratejilerinin planlanması ve icrası,
</p>

<p>
Alışveriş sitemiz ile iş ilişkisi içerisinde olan ilgili kişilerin hukuki, teknik ve ticari-iş güvenliğinin temini ile tarafımızın sunduğu ürün ve hizmetlerin ilgili kişilerin beğeni, kullanım alışkanlıkları ve ihtiyaçlarına göre özelleştirilerek ilgili kişilere önerilmesi ve tanıtılması için gerekli olan aktivitelerin planlanması ve icrası,
</p>

<p>
İlgililerin olası hak ve alacak taleplerinin tesisi
</p>

<p>
Yetkili kuruluşlara mevzuattan kaynaklı bilgi verilmesi
</p>

<p>
Ziyaretçi kayıtlarının oluşturulması ve takibi
</p>

<p>
Şirketimiz ve Şirketimiz adına şubelerimiz, çağrı merkezimiz, bağlı şirketlerimiz tarafından ya da internet sitelerimiz ile sosyal medya sayfalarımız veya ve bunlarla sınırlı olmamak üzere her türlü kanallar aracılığı ile Tüketicinin Korunması Hakkında Kanun, Perakende Ticaretin Düzenlenmesi Hakkında Kanun ve diğer yasal mevzuat kapsamında, yükümlülüklerin yerine getirilmesini sağlamak,
</p>

<p>
Müşterilere daha iyi hizmet verebilme, çeşitli avantajlar sağlayıp sunma, satış, pazarlama, bilgilendirme, promosyonlar hakkında bilgi verebilme, kampanya ve koşulları hakkında bilgi sağlama, anket, müşteri memnuniyet araştırmalarını yapabilme, satın alma işlemlerinizi sağlama hızlandırma, siparişlerinizi alma ve teslim edebilme,
</p>

<p>
Müşterilere yönelik kampanyaların oluşturulması, çapraz satış yapılması, hedef kitle belirlenmesi,
</p>

<p>
Müşteri hareketlerinin takip edilerek kullanıcı deneyimini arttırıcı faaliyetlerin yürütülmesi ve alışveriş sitemize ait internet sitesi ile mobil uygulamanın işleyişinin geliştirilmesi ve müşteri ihtiyaçlarına göre kişiselleştirilmesi, doğrudan ve doğrudan olmayan pazarlama, kişiye özel pazarlama ve yeniden pazarlama faaliyetlerinin yürütülmesi, kişiye özel segmentasyon, hedefleme, analiz ve şirket içi raporlama faaliyetlerinin yürütülmesi, pazar araştırmaları,
</p>

<p>
Müşteri memnuniyeti aktivitelerinin planlanması ve icrası ile müşteri ilişkileri yönetimi süreçlerinin planlanması ve icrası amaçlarıyla dahil olmak üzere dahil olmak üzere alışveriş sitemizin ürün ve/veya hizmetlerinin satış ve pazarlama süreçlerinin planlanması ve icrası, alışveriş sitemizin sunduğu ürün ve/veya hizmetlere bağlılık oluşturulması ve/veya arttırılması süreçlerinin planlanması ve icrası kapsamında Müşteri’nin vereceği onayı doğrultusunda işlenebilecek ve işbu Kişisel Verilerin Korunması Metnin’nde belirtilen taraflarla paylaşılabilecektir.
</p>

<p>
Alışveriş Sitemiz; online davranışsal reklamcılık ve pazarlama yapılabilmesi amacıyla siteye gelen kullanıcının üye olmasalar dahi sitedeki davranışlarını tarayıcıda bulunan bir cookie (çerez) ile ilişkilendirme ve görüntülenen sayfa sayısı, ziyaret süresi ve hedef tamamlama sayısı gibi metrikleri temel alan yeniden pazarlama listeleri tanımlama hakkını haizdir. Daha sonra bu kullanıcıya sitede ya da Görüntülü Reklam Ağı’ndaki diğer sitelerde, kullanıcıların ilgi alanlarına göre hedefe yönelik reklam içeriği gösterilebilir. Google AFS reklamlarının Alışveriş Sitemize yönlendirilmesi esnasında Google kullanıcıların tarayıcısına çerez yerleştirebilir veya bunlarda yer alan çerezleri okuyabilir veya bilgi toplamak amacı ile web işaretleri kullanabilir.
</p>

<p>
<strong>4. Müşterilere Ait Kişisel Verilerin Aktarımı:</strong>
</p>

<p>
Müşterilere ait kişisel veriler, alışveriş sitemiz tarafından sunulan ürün ve hizmetlerden ilgili kişileri faydalandırmak için gerekli çalışmaların iş birimleri tarafından yapılması ve ilgili iş süreçlerinin yürütülmesi, alışveriş sitemiz tarafından yürütülen ticari faaliyetlerin gerçekleştirilmesi için ilgili iş birimleri tarafından gerekli çalışmaların yapılması ve buna bağlı iş süreçlerinin yürütülmesi, alışveriş sitemizin ticari ve/veya iş stratejilerinin planlanması ve icrası, alışveriş sitemizin ve alışveriş sitemiz ile iş ilişkisi içerisinde olan ilgili kişilerin hukuki, teknik ve ticari-iş güvenliğinin temini ile alışveriş sitemizin sunduğu ürün ve hizmetlerin ilgili kişilerin beğeni, kullanım alışkanlıkları ve ihtiyaçlarına göre özelleştirilerek ilgili kişilere önerilmesi ve tanıtılması için gerekli olan aktivitelerin planlanması ve icrası da dahil olmak üzere Kanun’un 8. ve 9. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları çerçevesinde Şirket yetkilileri, iştiraklerimiz, iş ortaklarımız, tedarikçilerimiz, hissedarlarımız, kanunen yetkili kamu kurum ve kuruluşları ile özel kurumlar ile paylaşılabilecektir.
</p>

<p>
Kullanıcının Ad ve İletişim Bilgileri, ödeme aşamasında onaylayacağı ödeme kuruluşu çerçeve sözleşmesi uyarınca ve 9 Ocak 2008 tarihli ve 26751 sayılı Resmi Gazete’de yayımlanan Suç Gelirlerinin Aklanmasının ve Terörün Finansmanının Önlenmesine Dair Tedbirler Hakkında Yönetmelik uyarınca kimlik doğrulaması gerçekleştirilmesi amacıyla ödeme kuruluşlarıyla paylaşılabilecektir.
</p>

<p>
Alışveriş Sitemiz, kişisel verileri yukarıda belirtilen amaçlar dahilinde, 6698 sayılı Kanun’da öngörülen şartları sağlamak koşulu ile yurt içinde üçüncü kişilere aktarabileceği gibi yurt dışına da aktarabilecektir.
</p>

<p>
<strong>5. Kişisel Verilerin Toplanma Yöntemi ve Hukuki Sebebi:</strong>
</p>

<p>
Kişisel veriler, müşterilerden elektronik ortamda toplanmaktadır. Yukarıda belirtilen hukuki sebeplerle toplanan kişisel veriler Kanun’un 5. ve 6. maddelerinde ve bu Kişisel Verilerin Korunması Metninde belirtilen amaçlarla işlenebilmekte ve aktarılabilmektedir.
</p>

<p>
<strong>6. Kişisel Verilerin Saklanma Süreleri</strong>
</p>

<p>
Alışveriş Sitemiz, ilgili kanunlarda ve mevzuatlarda öngörülmesi durumunda kişisel verileri bu mevzuatlarda belirtilen süre boyunca saklamaktadır.
</p>

<p>
Kişisel verilerin ne kadar süre boyunca saklanması gerektiğine ilişkin mevzuatta bir süre düzenlenmemişse, Kişisel Veriler Alışveriş Sitemiz’in o veriyi işlerken yürütülen faaliyet ile bağlı olarak Alışveriş Sitemiz’in uygulamaları ve ticari yaşamının teamülleri uyarınca işlenmesini gerektiren süre kadar işlenmekte daha sonra silinmekte, yok edilmekte veya anonim hale getirilmektedir.
</p>

<p>
Kişisel verilerin işlenme amacı sona ermiş; ilgili mevzuat ve Alışveriş Sitemiz’in belirlediği saklama sürelerinin de sonuna gelinmişse; kişisel veriler yalnızca olası hukuki uyuşmazlıklarda delil teşkil etmesi veya kişisel veriye bağlı ilgili hakkın ileri sürülebilmesi veya savunmanın tesis edilmesi amacıyla saklanabilmektedir. Buradaki sürelerin tesisinde bahsi geçen hakkın ileri sürülebilmesine yönelik zaman aşımı süreleri ile zaman aşımı sürelerinin geçmesine rağmen daha önce aynı konularda Alışveriş Sitemiz’e yöneltilen taleplerdeki örnekler esas alınarak saklama süreleri belirlenmektedir. Bu durumda saklanan kişisel verilere herhangi bir başka amaçla erişilmemekte ve ancak ilgili hukuki uyuşmazlıkta kullanılması gerektiği zaman ilgili kişisel verilere erişim sağlanmaktadır. Burada da bahsi geçen süre sona erdikten sonra kişisel veriler silinmekte, yok edilmekte veya anonim hale getirilmektedir.
</p>




<p>
<strong>7. Kişisel Veri Sahibi Olarak Müşterilerin Hakları:</strong>
</p>

<p>
Kanun’un 11. maddesi uyarınca veri sahipleri; (i) kendileri ile ilgili kişisel veri işlenip işlenmediğini öğrenme, (ii) kişisel verileri işlenmişse buna ilişkin bilgi talep etme, (iii) kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme, (iv) yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme, (v) kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme, (vi) Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme, (vii) işlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme ve (viii) kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme haklarına sahiptir.
</p>

<p>
Söz konusu hakların kullanımına ilişkin talepler, kişisel veri sahipleri Alışveriş Sitemiz Tarafından 6698 sayılı Kanun Kapsamında belirtilen yöntemlerle iletilebilecektir. Alışveriş sitemiz söz konusu talepleri değerlendirerek 30 gün içerisinde sonuçlandıracaktır.
</p>

<p>
İşbu form üzerinde yer verilen hususlar ile ilgili olarak, hukuki ve teknolojik gelişmeler doğrultusunda değişiklikler söz konusu olabilecektir.
</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleShowKVKKCloseModal}>
            Kapat
          </Button>
        </Modal.Footer>
      </Modal>
    </FormContainer>
  );
};

export default RegisterScreen;