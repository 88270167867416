import { Row, Col, Accordion, Form, Button, Dropdown } from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';
import Product from '../components/Product';
import { useGetProductsQuery } from '../slices/productsApiSlice';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Paginate from '../components/Paginate';
import ProductCarousel from '../components/ProductCarousel';
import { useEffect, useState } from 'react';

const HomeScreen = () => {
  const { pageNumber, keyword } = useParams();
  
  // State for sorting and categories
  const [sortOrder, setSortOrder] = useState('Önerilen');

  const [printingHouse, setPrintingHouse] = useState([]);

  const [categories, setCategories] = useState({
    electronics: false,
    clothing: false,
    books: false,
    accessories: false,
  });

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const { data, isLoading, error } = useGetProductsQuery({
    keyword,
    pageNumber,
    sortOrder,  // Passing sortOrder to API query
    printingHouse,  // Passing printingHouse array to API query

  });



  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768); // Set to true for small screens
    };
    handleResize(); // Set initial state
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Handle sort order change
  const handleSortChange = (eventKey) => {
    setSortOrder(eventKey);
  };

  // Handle checkbox change for categories
  const handleCategoryChange = (e) => {
    setCategories({
      ...categories,
      [e.target.name]: e.target.checked,
    });
  };

  const handlePrintingHouseChange = (e) => {
    const { value, checked } = e.target;
    setPrintingHouse(prev => checked 
      ? [...prev, value]  // Add value if checked
      : prev.filter(item => item !== value)  // Remove if unchecked
    );
    console.log("Selected Printing Houses:", printingHouse); // Debugging

  };



  return (
    <>
      {!keyword ? (
        <ProductCarousel />
      ) : (
        <Link to='/' className='btn btn-block btn-dark'>
          Go Back
        </Link>
      )}

      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          <Row>
            {/* Filter Section */}
            <Col xs={12} sm={4} md={3} lg={2} className="mb-3">
              <h4>Filtre</h4>
              <Accordion defaultActiveKey= {isSmallScreen ? null: "0"}>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Matbaa</Accordion.Header>
                  <Accordion.Body>
                  <Form.Check
                  type="checkbox"
                  label="Ajans - Türk Matbaası / Ankara"
                  value="Ajans - Türk Matbaası / Ankara"
                  onChange={handlePrintingHouseChange}
                  />
                                  <Form.Check
                  type="checkbox"
                  label="Klişecilik ve Matbaacılık A.Ş / İstanbul"
                  value="Klişecilik ve Matbaacılık A.Ş / İstanbul"
                  onChange={handlePrintingHouseChange}
                />
                <Form.Check
                  type="checkbox"
                  label="Kıral Matbaası / İstanbul"
                  value="Kıral Matbaası / İstanbul"
                  onChange={handlePrintingHouseChange}
                />
                  </Accordion.Body>
                </Accordion.Item>

{/*                 <Accordion.Item eventKey="1">
                  <Accordion.Header>Tedavül</Accordion.Header>
                  <Accordion.Body>
                    <Form.Check type="checkbox" label="4 stars & up" />
                    <Form.Check type="checkbox" label="3 stars & up" />
                    <Form.Check type="checkbox" label="2 stars & up" />
                    <Form.Check type="checkbox" label="1 star & up" />
                  </Accordion.Body>
                </Accordion.Item> */}
              </Accordion>

              
            </Col>

            {/* Product Grid Section */}
            <Col xs={12} sm={8} md={9} lg={10}>
              {/* Sort Order Dropdown */}
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4>Katalog</h4>
                <Dropdown onSelect={handleSortChange}>
                  <Dropdown.Toggle variant="dark" id="dropdown-sort">
                    {/* If no sort order is selected, display 'Akıllı Sıralama' */}
                     {sortOrder}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="Önerilen">Önerilen</Dropdown.Item>
                    <Dropdown.Item eventKey="Artan Fiyat">Artan Fiyat</Dropdown.Item>
                    <Dropdown.Item eventKey="Azalan Fiyat">Azalan Fiyat</Dropdown.Item>
                    <Dropdown.Item eventKey="Basım Tarihi Artan">Basım Tarihi Artan</Dropdown.Item>
                    <Dropdown.Item eventKey="Basım Tarihi Azalan">Basım Tarihi Azalan</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <Row className="g-3">
                {data.products.map((product) => (
                  <Col key={product._id} xs={6} sm={6} md={4} lg={3}>
                    <Product product={product} />
                  </Col>
                ))}
              </Row>

              {/* Pagination */}
              <Paginate
                pages={data.pages}
                page={data.page}
                keyword={keyword ? keyword : ''}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default HomeScreen;
