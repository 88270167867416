import { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { useResetPasswordMutation } from "../slices/usersApiSlice";
import { toast } from "react-toastify";
import FormContainer from "../components/FormContainer";

const ResetPasswordScreen = () => {
    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const { token } = useParams();

    const [resetPassword, { isLoading }] = useResetPasswordMutation();

    const submitHandler = async (e) => {
        e.preventDefault();
        if(password !== confirmPassword) {
            toast.error('Passwords do no match');
            return;
        }else{
        try {
            await resetPassword({ token, password }).unwrap();
            toast.success("Password reset successful");
            navigate('/login');
        } catch (err) {
            toast.error(err?.data?.message || err.error);
        }
    }
    };

    return (
        <FormContainer>
            <h1>Şifreyi Sıfırla</h1>
            <Form onSubmit={submitHandler}>
                <Form.Group controlId="password" className="my-3">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Enter new password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    ></Form.Control>
                </Form.Group>
                <Form.Group controlId="confirmPassword" className="my-3">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Confirm password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}>
                </Form.Control>
                </Form.Group>
                <Button type="submit" variant="primary" className="mt-2 btn-dark" disabled={isLoading}>
                    Reset Password
                </Button>
            </Form>
        </FormContainer>
    );
};

export default ResetPasswordScreen;
