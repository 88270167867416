import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import PaymentLogos from './components/PaymentLogos';
import PreFooter from './components/PreFooter';



const App = () => {
  return (
    <>
      <Header />
      <main className='py-3'>
        <Container>
          <Outlet />
        </Container>       
      </main>
      <PreFooter />
      <PaymentLogos /> {/* Add the PaymentLogos component above the footer */}
      <Footer />
      <ToastContainer />
    </>
  )
}

export default App
