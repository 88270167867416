import { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useForgotPasswordMutation } from "../slices/usersApiSlice";
import { toast } from "react-toastify";
import FormContainer from "../components/FormContainer";

const ForgotPasswordScreen = () => {
    const [email, setEmail] = useState("");

    const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            await forgotPassword({ email }).unwrap();
            toast.success("Password reset email sent");
        } catch (err) {
            toast.error(err?.data?.message || err.error);
        }
    };

    return (
        <FormContainer>
            <h1>Şifremi Unuttum</h1>
            <Form onSubmit={submitHandler}>
                <Form.Group controlId="email" className="my-3">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    ></Form.Control>
                </Form.Group>
                <Button type="submit" variant="primary" className="mt-2 btn-dark" disabled={isLoading}>
                    Submit
                </Button>
            </Form>
        </FormContainer>
    );
};

export default ForgotPasswordScreen;
