import React from "react";
import { Container } from "react-bootstrap";

const PaymentLogos = () => {
    const paymentLogos = [
        '/uploads/paymentSVG/iyzico1.svg',
        '/uploads/paymentSVG/mastercard.svg',
        '/uploads/paymentSVG/maestro.svg',
        '/uploads/paymentSVG/visa.svg',
        '/uploads/paymentSVG/visa-electron.svg',
        '/uploads/paymentSVG/american.svg',
        '/uploads/paymentSVG/troy.svg',
        '/uploads/paymentSVG/security.svg',
    ];

    return (
        <div className='payment-logos-container'>
          <Container className="d-flex justify-content-center">
            <div className="logos-wrapper">
              {paymentLogos.map((logo, index) => (
                <img key={index} src={logo} alt={`Payment logo ${index + 1}`} className="payment-logo" />
              ))}
            </div>
          </Container>
        </div>
      );
};

export default PaymentLogos;