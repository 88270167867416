import { Helmet } from 'react-helmet-async';

const CancellationAndRefundScreen = () => (
  <>
    <Helmet>
      <title>İptal ve İade Koşulları</title>
      <meta name="description" content="İptal ve İade Koşulları" />
    </Helmet>
    <div style={{ padding: '20px', lineHeight: '1.6', fontFamily: 'Arial, sans-serif' }}>
      <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>İptal ve İade Koşulları</h1>
      <p style={{ whiteSpace: 'pre-wrap' }}>
        <strong> GENEL:</strong>
        {'\n'}<br />1. Kullanmakta olduğunuz web sitesi üzerinden elektronik ortamda sipariş verdiğiniz takdirde, size sunulan ön bilgilendirme formunu ve mesafeli satış sözleşmesini kabul etmiş sayılırsınız.
        {'\n'}<br />2. Alıcılar, satın aldıkları ürünün satış ve teslimi ile ilgili olarak 6502 sayılı Tüketicinin Korunması Hakkında Kanun ve Mesafeli Sözleşmeler Yönetmeliği (RG:27.11.2014/29188) hükümleri ile yürürlükteki diğer yasalara tabidir.
        {'\n'}<br />3. Ürün sevkiyat masrafı olan kargo ücretleri alıcılar tarafından ödenecektir.
        {'\n'}<br />4. Satın alınan her bir ürün, 30 günlük yasal süreyi aşmamak kaydı ile alıcının gösterdiği adresteki kişi ve/veya kuruluşa teslim edilir. Bu süre içinde ürün teslim edilmez ise, Alıcılar sözleşmeyi sona erdirebilir.
        {'\n'}<br />5. Satın alınan ürünler, www.pulsepeti.com 'A ait olan www.pulsepeti.com adlı internet sitesinde belirtilen niteliklere uygun ve varsa sertifikaları ile birlikte teslim edilecektir.
        {'\n'}<br />6. Satın alınan ürünün satılmasının imkansızlaşması durumunda, www.pulsepeti.com bu durumu öğrendiğinden itibaren 3 gün içinde yazılı olarak alıcıya bu durumu bildirmek zorundadır. 14 gün içinde de tahsil edilen toplam bedel Alıcı'ya iade edilmek zorundadır.
        {'\n'}<br /><strong>SATIN ALINAN ÜRÜN BEDELİ ÖDENMEZ İSE:</strong>; 
        {'\n'}<br />Alıcı, satın aldığı ürün bedelini ödemez veya banka kayıtlarında iptal ederse;
        {'\n'}<br />7. www.pulsepeti.com 'un ürünü teslim yükümlülüğü sona erer.
        {'\n'}<br />8. www.pulsepeti.com 'un bu sebeple uğradığı zararları tazmin etme hakkı saklıdır.
        {'\n'}<br />9. Alıcının üyeliği, yeni satın alma ve pey verme işlemleri site yöneticisi tarafından kapatılabilir.
        {'\n'}<br />
        {'\n'}<br /><strong>KREDİ KARTININ YETKİSİZ KULLANIMI İLE YAPILAN ALIŞVERİŞLER:</strong>; 
        {'\n'}<br />10. Ürün teslim edildikten sonra, alıcının ödeme yaptığı kredi kartının yetkisiz kişiler tarafından haksız olarak kullanıldığı tespit edilirse ve satılan ürün bedeli ilgili banka veya finans kuruluşu tarafından www.pulsepeti.com 'a ödenmez ise, Alıcı, sözleşme konusu ürünü 3 gün içerisinde nakliye gideri ALICI'ya ait olacak şekilde www.pulsepeti.com 'a iade etmek zorundadır.
        {'\n'}<br /><strong>ÖNGÖRÜLEMEYEN SEBEPLERLE ÜRÜN SÜRESİNDE TESLİM EDİLEMEZ İSE:</strong>; 
        {'\n'}<br />11. www.pulsepeti.com 'un öngöremeyeceği mücbir sebepler oluşursa ve bu nedenle ürün süresinde teslim edilemez ise, durum Alıcı'ya bildirilir. Alıcı, siparişin iptalini veya engel ortadan kalkana dek teslimatın ertelenmesini talep edebilir. Alıcı siparişi iptal ederse; ödemeyi nakit ile yapmış ise iptalinden itibaren 14 gün içinde kendisine nakden bu ücret ödenir. Alıcı, ödemeyi kredi kartı ile yapmış ise ve iptal ederse, bu iptalden itibaren yine 14 gün içinde ürün bedeli bankaya iade edilir, ancak bankanın alıcının hesabına 2-3 hafta içerisinde aktarması olasıdır.
        {'\n'}<br /><strong>ALICININ ÜRÜNÜ KONTROL ETME YÜKÜMLÜLÜĞÜ:</strong>
        {'\n'}<br />12. Alıcı, sözleşme konusu mal/hizmeti teslim almadan önce muayene edecek; ezik, kırık, ambalajı yırtılmış vb. hasarlı ve ayıplı mal/hizmeti kargo şirketinden teslim almayacaktır. Teslim alınan mal/hizmetin hasarsız ve sağlam olduğu kabul edilecektir. ALICI, cayma hakkını kullanılacaksa ürünleri hiçbir şekilde deforme etmeden faturasıyla birlikte www.pulsepeti.com 'a iade edecektir.
        {'\n'}<br /><strong>CAYMA HAKKI:</strong>
        {'\n'}<br />13. Alıcı, işbu Sözleşme konusu ürünün kendisine veya gösterdiği adresteki kişiye/kuruluşa tesliminden itibaren 14 (on dört) gün içerisinde herhangi bir gerekçe göstermeksizin işbu Sözleşmeden cayma hakkına sahiptir. Alıcı'nın, cayma hakkını kullanabilmesi için, bu süre içinde www.pulsepeti.com 'a bildirimde bulunması şarttır. Alıcı'nın Cayma hakkının kullanılabilmesi için ürüne zarar gelmemiş olması kaydıyla www.pulsepeti.com 'a iletişim bilgileri vasıtasıyla yazılı olarak bildirimde bulunmak zorundadır. İşbu bildirim iadeli taahhütlü posta, faks veya e-posta yoluyla olabilir. Alıcı'nın kusurundan kaynaklanan bir nedenle malın değerinde bir azalma olursa veya iade imkânsızlaşırsa Alıcı kusuru oranında www.pulsepeti.com 'un zararlarını tazmin etmekle yükümlüdür.
        {'\n'}<br />14. Cayma hakkı bildiriminin www.pulsepeti.com 'a ulaşmasını takiben www.pulsepeti.com bildirimin kendisine ulaştığına ilişkin teyit bilgisini ALICI ile paylaşacak ve bildirimin ulaşma tarihini takiben de 14 (on dört) gün içinde www.pulsepeti.com cayma hakkına konu ürünün/ürünlerin bedelini ödeme şekline uygun bir şekilde Alıcı'ya iade edecektir.
        {'\n'}<br />15. Cayma hakkının kullanımı halinde, cayma hakkının kullanıldığına ilişkin bildirim tarihinden itibaren on (10) gün içinde aşağıda bildirilen www.pulsepeti.com 'un adresine ürünün elden iade edilmesi veya kargo yoluyla gönderilmesi zorunludur. Kargo yoluyla yapılacak iadelerde masraflar alıcıya ait olacaktır. Bu iade işleminde Ürün'ün kutusu, ambalajı, varsa standart aksesuarları ile birlikte eksiksiz ve hasarsız olarak teslim edilmesi gerekmektedir.
        {'\n'}<br />16. Cayma hakkı kullanımı mümkün olan hallerde, ALICI, cayma süresi içinde malı, işleyişine, teknik özelliklerine ve kullanım talimatlarına uygun bir şekilde kullanmadığı takdirde meydana gelen değişiklik ve bozulmalardan kanun gereği sorumludur. Buna göre, cayma tarihine kadarki süreçte Ürün'ün kullanım talimatlarına, teknik özelliklerine ve işleyişine uygun bir şekilde kullanılmamasından ötürü değişiklik veya bozulma olursa ALICI cayma hakkını kaybedebilir; SATICI tarafından kabul edildiği hallerde, iade edilecek Ürün bedelinden değişiklik / bozulma kadar indirim yapılır.
        {'\n'}<br /><strong>17. CAYMA HAKKININ İSTİSNALARI</strong>
        {'\n'}<br />27.11.2014 tarihli 29188 sayılı Resmi Gazetede yayımlanan MESAFELİ SÖZLEŞMELER YÖNETMELİĞİ uyarınca;
        {'\n'}<br /> a. Fiyatı finansal piyasalardaki dalgalanmalara bağlı olarak değişen ve satıcı veya sağlayıcının kontrolünde olmayan mal veya hizmetlere ilişkin sözleşmeler.
        {'\n'}<br /> b. Tüketicinin istekleri veya kişisel ihtiyaçları doğrultusunda hazırlanan mallara ilişkin sözleşmeler.
        {'\n'}<br /> c. Çabuk bozulabilen veya son kullanma tarihi geçebilecek malların teslimine ilişkin sözleşmeler.
        {'\n'}<br /> d. Tesliminden sonra ambalaj, bant, mühür, paket gibi koruyucu unsurları açılmış olan mallardan; iadesi sağlık ve hijyen açısından uygun olmayanların teslimine ilişkin sözleşmeler.
        {'\n'}<br /> e. Tesliminden sonra başka ürünlerle karışan ve doğası gereği ayrıştırılması mümkün olmayan mallara ilişkin sözleşmeler.
        {'\n'}<br /> f. Malın tesliminden sonra ambalaj, bant, mühür, paket gibi koruyucu unsurları açılmış olması halinde maddi ortamda sunulan kitap, dijital içerik ve bilgisayar sarf malzemelerine ilişkin sözleşmeler.
        {'\n'}<br /> g. Abonelik sözleşmesi kapsamında sağlananlar dışında, gazete ve dergi gibi süreli yayınların teslimine ilişkin sözleşmeler.
        {'\n'}<br /> h. Belirli bir tarihte veya dönemde yapılması gereken, konaklama, eşya taşıma, araba kiralama, yiyecek-içecek tedariki ve eğlence veya dinlenme amacıyla yapılan boş zamanın değerlendirilmesine ilişkin sözleşmeler.
        {'\n'}<br /> i. Elektronik ortamda anında ifa edilen hizmetler veya tüketiciye anında teslim edilen gayrimaddi mallara ilişkin sözleşmeler.
        {'\n'}<br /> j. Cayma hakkı süresi sona ermeden önce, tüketicinin onayı ile ifasına başlanan hizmetlere ilişkin sözleşmeler, bakımından cayma hakkının kullanılması mümkün değildir.
        {'\n'}<br /><strong>SATICININ CAYMA HAKKI BİLDİRİMİ YAPILACAK İLETİŞİM BİLGİLERİ:</strong>
        {'\n'}<br /> Şirket: Erdem Kurt www.pulsepeti.com
        {'\n'}<br /> Adres: Meşrutiyet Mah. Konur sk. Özsoy İşhanı No: 25 İç Kapı No: 13 Çankaya/Ankara
        {'\n'}<br /> Telefon: +90 (533) 2508029
        {'\n'}<br /> Eposta: destek@pulsepeti.com
        {'\n'}<br /><strong>BORÇLUNUN TEMERRÜDÜ</strong>
        {'\n'}<br />ALICI, kredi kartı ile yapmış olduğu işlemlerinde temerrüde düşmesi halinde kart sahibi bankanın kendisi ile yapmış olduğu kredi kartı sözleşmesi çerçevesinde faiz ödeyeceğini ve bankaya karşı sorumlu olacağını kabul, beyan ve taahhüt eder. Bu durumda ilgili banka hukuki yollara başvurabilir; doğacak masrafları ve vekâlet ücretini Alıcı'dan talep edebilir ve her koşulda Alıcı'nın borcundan dolayı temerrüde düşmesi halinde, ALICI, borcun gecikmeli ifasından dolayı www.pulsepeti.com 'un uğradığı zarar ve ziyanını ödeyeceğini kabul, beyan ve taahhüt eder.
      </p>
    </div>
  </>
);

export default CancellationAndRefundScreen;