import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Form, Button } from 'react-bootstrap';
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import FormContainer from "../../components/FormContainer";
import { toast } from "react-toastify";
import { useUpdateProductMutation, useGetProductDetailsQuery, useUploadProductImageMutation } from "../../slices/productsApiSlice";



const ProductEditScreen = () => {

  const { id: productId } = useParams();

  const [name, setName] = useState('');
  const [price, setPrice] = useState(0);
  const [image, setImage] = useState('');
  const [category, setCategory] = useState('');
  const [seriesIssueDate, setSeriesIssueDate] = useState('');
  const [countInStock, setCountInStock] = useState('');
  const [description, setDescription] = useState('');
  const [printingProcess, setPrintingProcess] = useState('');
  const [printingHouse, setPrintingHouse] = useState('');
  const [denominationValueOfStamp, setDenominationValueOfStamp] = useState('');
  const [brand, setBrand] = useState('');
  
  const { data: product, isLoading, refetch, error} = useGetProductDetailsQuery(productId);

  const [ updateProduct, { isLoading: loadingUpdate}] = useUpdateProductMutation();

  const [ uploadProductImage , { isLoading: loadingUpload }] = useUploadProductImageMutation();

  const navigate = useNavigate();

  useEffect(() => {
    if(product){
        setName(product.name);
        setPrice(product.price);
        setImage(product.image);
        setCategory(product.category);
        setSeriesIssueDate(product.seriesIssueDate);
        setCountInStock(product.countInStock);
        setDescription(product.description);
        setPrintingProcess(product.setPrintingProcess);
        setPrintingHouse(product.setPrintingHouse);
        setDenominationValueOfStamp(product.setDenominationValueOfStamp);
        setBrand(product.setBrand);
    }
  }, [product]);

  const submitHandler = async (e) => {
    e.preventDefault();
    const updatedProduct = {
        productId,
        name,
        price,
        image,
        category,
        seriesIssueDate,
        countInStock,
        description,
        printingProcess,
        printingHouse,
        denominationValueOfStamp,
        brand,
    };
    
    try {
      await updateProduct(updatedProduct).unwrap();
      toast.success('Product updated');
      refetch(); // Refetch the product details after update
      navigate('/admin/productlist');
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  }

  const uploadFileHandler = async (e) => {
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    try {
      const res = await uploadProductImage(formData).unwrap();
      toast.success(res.message);
      setImage(res.image);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  }

  return <>
    <Link to='/admin/productlist' className="btn btn-dark my-3">
        Go Back
    </Link>
    <FormContainer>
        <h1>Ürün Değiştirme</h1>
        {loadingUpdate && <Loader />}

        {isLoading ? <Loader />: error ? <Message variant='danger'>
            {error}
        </Message>: (
            <Form onSubmit={ submitHandler }>
                <Form.Group controlId='name'>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type='name'
                      placeholder="Enter Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}></Form.Control>
                </Form.Group>
                <Form.Group controlId='price' className="my-2">
                    <Form.Label>Price</Form.Label>
                    <Form.Control
                      type='number'
                      placeholder="Enter Price"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}></Form.Control>
                </Form.Group>
                

                <Form.Group controlId="image" className="my-2">
                  <Form.Label>Image</Form.Label>
                  <Form.Control 
                    type='text' 
                    placeholder="Enter Image URL"
                    value={image}
                    onChange={ (e) => setImage }></Form.Control>
                  <Form.Control type='file' label='Choose File' onChange={uploadFileHandler}></Form.Control>
                </Form.Group>
                {loadingUpload && <Loader />}

                <Form.Group controlId='category' className="my-2">
                    <Form.Label>Category</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder="Enter Category"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}></Form.Control>
                </Form.Group>

                <Form.Group controlId='seriesIssueDate' className="my-2">
                    <Form.Label>seriesIssueDate</Form.Label>
                    <Form.Control
                      type='date'
                      placeholder="Enter Product Date"
                      value={seriesIssueDate}
                      onChange={(e) => setSeriesIssueDate(e.target.value)}></Form.Control>
                </Form.Group>

                <Form.Group controlId='countInStock' className="my-2">
                    <Form.Label>Count In Stock</Form.Label>
                    <Form.Control
                      type='number'
                      placeholder="Enter Count In Stock"
                      value={countInStock}
                      onChange={(e) => setCountInStock(e.target.value)}></Form.Control>
                </Form.Group>

                <Form.Group controlId='description' className="my-2">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder="Enter Description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}></Form.Control>
                </Form.Group>
                <Form.Group controlId='printingProcess' className="my-2">
                    <Form.Label>Printing Process</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder="Enter Printing Process"
                      value={printingProcess}
                      onChange={(e) => setPrintingProcess(e.target.value)}></Form.Control>
                </Form.Group>
                <Form.Group controlId='printingHouse' className="my-2">
                    <Form.Label>Printing House</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder="Enter Printing House"
                      value={printingHouse}
                      onChange={(e) => setPrintingHouse(e.target.value)}></Form.Control>
                </Form.Group>
                <Form.Group controlId='denominationValueOfStamp' className="my-2">
                    <Form.Label>Denomination Value Of Stamp</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder="Enter Denomination Value Of Stamp"
                      value={denominationValueOfStamp}
                      onChange={(e) => setDenominationValueOfStamp(e.target.value)}></Form.Control>
                </Form.Group>
                <Form.Group controlId='brand' className="my-2">
                    <Form.Label>Brand</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder="Enter Brand"
                      value={brand}
                      onChange={(e) => setBrand(e.target.value)}></Form.Control>
                </Form.Group>
                
                <Button
                  type='submit'
                  variant="primary"
                  className="my-2 btn-dark">
                    Update
                </Button>
            </Form>
        )}
    </FormContainer>
  
  </>
  
}

export default ProductEditScreen