import { useState, useEffect } from 'react';
import { Container, Row, Col, Collapse } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const PreFooter = () => {
  const [openCategory, setOpenCategory] = useState(true);
  const [openContact, setOpenContact] = useState(true);
  const [openContracts, setOpenContracts] = useState(true);
  const [openDistanceSelling, setOpenDistanceSelling] = useState(true);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  // Update state based on screen width
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };
    
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Function to conditionally show "+" or "-" only on smaller screens
  const toggleSign = (isOpen) => {
    return isSmallScreen ? (isOpen ? '-' : '+') : '';
  };

  return (
    <div className="pre-footer bg-light py-4">
      <Container>
        <Row>

          {/* Contact Info Column */}
          <Col md={4} className="mb-3">
            <h6 
              onClick={() => isSmallScreen && setOpenContact(!openContact)} 
              style={{ cursor: 'default' }}  // Ensure the heading has default cursor
            >
              Pul Sepeti <span className="toggle-sign">{toggleSign(openContact)}</span>
            </h6>
            <Collapse in={isSmallScreen ? openContact : true}>
              <ul className="list-unstyled">
                <li>Pul Sepeti, 1970'li yıllarda başlayan bir pul koleksiyonu tutkusunun günümüzdeki değerli bir yansımasıdır.</li>
                <li>Email: destek@pulsepeti.com</li>
              </ul>
            </Collapse>
          </Col>
          {/* Categories Column */}
          <Col md={4} className="mb-3">
            <h6 
              onClick={() => isSmallScreen && setOpenCategory(!openCategory)} 
              style={{ cursor: 'default' }}  // Ensure the heading has default cursor
            >
              Kategoriler <span className="toggle-sign">{toggleSign(openCategory)}</span>
            </h6>
            <Collapse in={isSmallScreen ? openCategory : true}>
              <ul className="list-unstyled">
                <li>Türkiye Cumhuriyeti</li>  {/* Links retain default pointer cursor */}
                <li>Türkiye Büyük Millet Meclisi Hükümeti</li>
                <li>Osmanlı İmparatorluğu</li>
                <li>Pul Albümleri</li>
                <li>Pul Maşası ve Büyüteç</li>
              </ul>
            </Collapse>
          </Col>



          {/* Product Contracts Column */}
          <Col md={4} className="mb-3">
            <h6 
              onClick={() => isSmallScreen && setOpenContracts(!openContracts)} 
              style={{ cursor: 'default' }}  // Ensure the heading has default cursor
            >
              Önemli Bilgiler <span className="toggle-sign">{toggleSign(openContracts)}</span>
            </h6>
            <Collapse in={isSmallScreen ? openContracts : true}>
              <ul className="list-unstyled">
                <li><Link to="/mesafeli-satis-sozlesmesi">Mesafeli Satış Sözleşmesi</Link></li>  {/* Links retain default pointer cursor */}
                <li><Link to="/gizlilik-sozlesmesi">Gizlilik Sözleşmesi</Link></li>
                <li><Link to="/iptal-ve-iade-koşullari">İptal ve İade Koşulları</Link></li>
                <li><Link to="/kvkk-aydinlatma-metni">KVKK Aydınlatma Metni</Link></li>
              </ul>
            </Collapse>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PreFooter;
