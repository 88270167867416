import { Helmet } from 'react-helmet-async';

const KvkkScreen = () => (
  <>
    <Helmet>
      <title>KVKK Aydınlatma Metni</title>
      <meta name="kvkk" content="KVKK Aydınlatma Metni" />
    </Helmet>
    <div>
      <h1>KVKK Aydınlatma Metni</h1>
      <p> 6102 Sayılı Türk Ticaret Kanunu, 5549 sayılı Suç Gelirlerinin Aklanmasının Önlenmesi Hakkında Kanun, 4358 sayılı Vergi Kimlik Numarası Kullanımının Yaygınlaştırılması Hakkında Kanun ile diğer ilgili tüm kanun ve bu kanunlara dayalı tüm ulusal/uluslararası düzenlemeler kapsamında doğrudan şirketimiz tarafından vermekte olduğumuz hizmetler, ilgili kişilere ait, kişisel ve/veya özel nitelikli kişisel verilerin işlenmesini gerektirmektedir.

Veriler, "6698 sayılı Kişisel Verilerin Korunması Kanunu" kapsamında aşağıda açıklandığı çerçevede, otomatik olan ya da olmayan yollarla elde edilebilecek, kaydedilebilecek, muhafaza edilebilecek, depolanabilecek, değiştirilebilecek, yeniden düzenlenebilecek, sınıflandırılabilecek, açıklanabilecek, mevzuatın izin verdiği ölçüde 3. kişilere aktarılabilecek veya diğer şekillerde işlenebilecektir.

Kişisel verileriniz, denetleyici ve düzenleyici makamlar ilgili kamu kuruluşları, adli ve idari makamlara, diğer mevzuat hükümlerinin izin verdiği kişi veya kuruluşlara mevzuatın izin verdiği ölçüde aktarılabilecektir.

Sizinle gerçekleştirmekte olduğumuz işlemlere ilişkin dokümanların saklanmasına dair yasal zorunluluklarımız bulunmaktadır.

Kişisel verilerinizin silinmesini veya yok edilmesini talep etmeniz halinde, yasal zorunluluklar kapsamında belirlenen sürenin sonunda bu talebinizi yerine getirmemiz en erken VUK. Yazılı süreler sonunda mümkün olabilecektir.


.</p>
    </div>
  </>
);

export default KvkkScreen;