import { USERS_URL } from "../constants";
import { apiSlice } from "./apiSlice";

export const usersApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (data) => ({
                url: `${USERS_URL}/auth`,
                method: 'POST',
                body: data,
            }),
        }),
        register: builder.mutation({
            query: (data) => ({
                url: `${USERS_URL}`,
                method: 'POST',
                body: data,
            }),
        }),
        logout: builder.mutation({
            query: () => ({
                url: `${USERS_URL}/logout`,
                method: 'POST',
            }),
        }),
        profile: builder.mutation({
            query: (data) => ({
                url: `${USERS_URL}/profile`,
                method: 'PUT',
                body: data,
            })
        }),
        getUsers: builder.query ({
            query: () => ({
                url: USERS_URL
            }),
            providesTags: ['Users'],
            keepUnusedDataFor: 5,
        }),
        deleteUser: builder.mutation ({
            query: (userId) => ({
                url: `${USERS_URL}/${userId}`,
                method: 'DELETE',
            }),
        }),
        getUserDetails: builder.query ({
            query: (userId) => ({
                url: `${USERS_URL}/${userId}`,
            }),
            keepUnusedDataFor: 5,
        }),
        updateUser: builder.mutation({
            query: (data) => ({
                url: `${USERS_URL}/${data.userId}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Users'],
        }),
        forgotPassword: builder.mutation({
            query: (data) => ({
                url: `${USERS_URL}/forgotpassword`,
                method: 'POST',
                body: data,
            }),
        }),
        resetPassword: builder.mutation({
            query: (data) => ({
                url: `${USERS_URL}/resetpassword/${data.token}`,
                method: 'PUT',
                body: { password: data.password },
            }),
        }),
        createUserAddress: builder.mutation({
            query: (data) => ({
                url: `${USERS_URL}/address`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['UserAddresses'],
        }),
        getUserAddresses: builder.query({
            query: () => ({
                url: `${USERS_URL}/address`,
                method: 'GET',
            }),
            providesTags: ['UserAddresses'],
        }),
    }),
});

export const { useLoginMutation, useLogoutMutation, 
    useRegisterMutation, useProfileMutation, useGetUsersQuery, useDeleteUserMutation,
    useGetUserDetailsQuery, useUpdateUserMutation, useForgotPasswordMutation, useResetPasswordMutation,
    useCreateUserAddressMutation, useGetUserAddressesQuery } = usersApiSlice;