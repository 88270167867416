import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Product = ({ product }) => {
  return (
    <Card className='my-1 p-3 rounded product-card'>
      <Link to={`/product/${product._id}`}>
        <Card.Img src={product.image} variant='top' className='product-image' alt={`${product.name}`} />
      </Link>

      <Card.Body>
        <Link to={`/product/${product._id}`}>
          <Card.Title as='div' className='product-title'>
            {product.name}
          </Card.Title>
        </Link>

        <Card.Text as='h4' className='product-price'>
          ₺{product.price}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default Product;
