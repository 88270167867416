import { Helmet } from 'react-helmet-async';

const PrivacyPolicyScreen = () => (
  <>
    <Helmet>
      <title>Gizlilik Politikası</title>
      <meta name="description" content="Gizlilik Politikası" />
    </Helmet>
    <div>
      <h1>Gizlilik Politikası</h1>
      <p></p>
    </div>
  </>
);

export default PrivacyPolicyScreen;