import React from 'react'
import { Helmet } from 'react-helmet-async'

const Meta = ({ title, description, keywords }) => {
  return (
    <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
    </Helmet>
  );
};

Meta.defaultProps = {
    title: 'Pul Sepeti',
    description: 'Filateli, Koleksiyon Pulları',
    keywords: 'pul, filateli, koleksiyon, posta pulları',
};

export default Meta