import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const CheckoutSteps = ({step1, step2, step3, step4}) => {
  return (
    <Nav className='justify-content-center mb-4'>
      <Nav.Item>
        { step1 ? (
            <LinkContainer to='/login'>
              <Nav.Link className='black-link'>Giriş</Nav.Link>
              </LinkContainer>
        ) : (
            <Nav.Link disabled>Giriş</Nav.Link>
        )}
      </Nav.Item>
      <Nav.Item>
        { step2 ? (
            <LinkContainer to='/shipping'>
              <Nav.Link className='black-link'>Teslimat</Nav.Link>
              </LinkContainer>
        ) : (
            <Nav.Link disabled>Shipping</Nav.Link>
        )}
      </Nav.Item>
      <Nav.Item>
        { step3 ? (
            <LinkContainer to='/placeorder'>
              <Nav.Link className='black-link'>Sipariş Özeti</Nav.Link>
              </LinkContainer>
        ) : (
            <Nav.Link disabled>Sipariş Özeti</Nav.Link>
        )}
      </Nav.Item>
      <Nav.Item>
        { step4 ? (
            <LinkContainer to='/payment'>
              <Nav.Link className='black-link'>Ödeme</Nav.Link>
              </LinkContainer>
        ) : (
            <Nav.Link disabled>Ödeme</Nav.Link>
        )}
      </Nav.Item>
    </Nav>
  );
};

export default CheckoutSteps;